import React from "react";
import SideNavBar from "../../../components/side-nav-bar";
import Footer from "../../../components/footer";
import SearchBar from "../../../components/searchbar";
import { useState, useEffect } from "react";
import { axiosInstance } from "../../../utils/apiConfig";
import { currencyFormat, searchFunction } from "../../../utils/reusables";
import '../../../styles/admin-bills.scss'

const PaymentReport = () => {
  const [search, setSearch] = useState("");
  const [PaymentReportData, setPaymentReportData] = useState([]);

  const flattenObject = (arr) => {
    let newArr = [];

    arr.forEach((obj) => {
      const { individual, ...rest } = obj;
      const { first_name, last_name, _id, ...individualRest } = individual;

      newArr.push({
        ...rest,
        first_name,
        last_name,
        userId: _id,
        ...individualRest,
      });
    });

    return newArr;
  };

  const searchKeys = [
    "bill_name",
    "bill_amount",
    "first_name",
    "last_name",
    "status",
    "modeofpayment",
    "_id",
    "createdAt",
  ];

  const handleSearch = (find) => {
    setSearch(find);
  };

  const Table = ({ data }) => {
    return (
      <table className="block max-h-[700px] overflow-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full capitalize">
        <thead className="block sticky top-0">
          <tr className="flex space-x-5 mb-4">
            <th className="min-w-[64px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-2xl">
              S/N
            </th>

            <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Bill Item
            </th>

            <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Amount
            </th>

            <th className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Assignee
            </th>

            <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Payment Status
            </th>

            <th className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Mode Of Payment
            </th>

            <th className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Transaction Reference
            </th>

            <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Date Created
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <TableItem
              key={index}
              index={index}
              billname={item?.bill_name}
              amount={item?.bill_amount}
              assignee={item?.first_name + " " + item?.last_name}
              payment_status={item?.status}
              modeofpayment={item?.modeofpayment}
              transaction_reference={item?._id}
              datecreated={item?.createdAt}
            />
          ))}
        </tbody>
      </table>
    );
  };

  const TableItem = ({
    index,
    billname,
    amount,
    assignee,
    payment_status,
    modeofpayment,
    transaction_reference,
    datecreated,
  }) => {
    return (
      <tr className="flex space-x-5 mb-4">
        <td className="min-w-[64px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l font-bold">
          {index + 1}
        </td>

        <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l text-center">
          {billname}
        </td>

        <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
          {currencyFormat("NGN", amount)}
        </td>

        <td className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
          {assignee}
        </td>

        <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
          {payment_status}
        </td>

        <td className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
          {modeofpayment || "N/A"}
        </td>

        <td className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
          {transaction_reference}
        </td>

        <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
          {datecreated?.split("T")[0]}
        </td>
      </tr>
    );
  };

  const getPaymentReportData = async () => {
    try {
      const response = await axiosInstance.get("/admin/existing-bills", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      });

      const hasNullValue = (obj) => {
        for (const value of Object.values(obj)) {
          if (value === null) {
            return true;
          }
          if (typeof value === "object" && hasNullValue(value)) {
            return true;
          }
        }
        return false;
      };

      const filteredArray = response.data
        .reverse()
        .filter((obj) => !hasNullValue(obj));
      const data = flattenObject(filteredArray);

      setPaymentReportData(data);
    } catch (err) {}
  };

  useEffect(() => {
    getPaymentReportData();
  }, []);

  return (
    <>
      <div className="max-w-screen flex flex-col lg:flex-row lg:space-x-4 text-[#000000] mobile-mt">
        <SideNavBar />

        <div className="flex flex-col space-y-10 px-10 py-20 w-screen md:w-4/5 payment-report-container">
          {/* Search Bar */}
          <SearchBar onSearch={handleSearch} />

          <div className="flex flex-col space-y-3 p-2 border-solid border-2 border-gray-200">
            <h1 className="text-3xl font-medium border-b border-gray-300 py-2 pl-2 header-text">
              Payment Report
            </h1>

            {/* Insert Table */}
            <Table
              data={searchFunction(PaymentReportData, search, searchKeys)}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentReport;
