import Button from "../../components/button";
import { Link, useLocation } from "react-router-dom";
import { axiosInstance } from "../../utils/apiConfig";
import queryString from "query-string";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect } from "react";

const Verification = () => {
  const location = useLocation();
  const { email, token } = queryString.parse(location.search);
  useEffect(() => {
    const formData = new FormData();
    axiosInstance
      .get(`/users/email-verification?email=${email}&token=${token}`, formData)
      .then((response) => {})
      .catch((error) => {});
  }, []);

  return (
    <>
      <div className=" container bg-white flex justify-center mt-32">
        <form
          id="email-verification"
          className=" bg-white max-w-xl mx-auto rounded-xl py-10 px-16"
        >
          <div className=" flex flex-col justify-center">
            <span className="flex justify-center font-bold text-4xl">
              Congratulations!!
            </span>
            <span className=" mt-10">
              You have successfully verified your email, You can login.
            </span>
          </div>

          <div className=" flex justify-center mt-24 ">
            <Link to={"/login"}>
              {" "}
              <Button classname=" px-20">Login</Button>{" "}
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};
export default Verification;
