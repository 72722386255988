import Input from "../../components/input";
import Button from "../../components/button";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance, encryptionKey } from "../../utils/apiConfig";
import CryptoJS from "crypto-js";
import Logo from "../../assets/images/ANSTesters-black-removebg.png";
import "../../styles/login.scss";
import Private from "../../assets/images/private.png"
import View from "../../assets/images/view.png"

const Login = ({setupSocket, setMobileNav}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    axiosInstance
      .post(`/users/login`, formData)
      .then(function (response) {
        setIsButtonDisabled(false);
        notify(response.data.message);

        localStorage.setItem("datakey", JSON.stringify(response.data.token));

        localStorage.setItem(
          "user-role",
          JSON.stringify(response.data.userObject.role)
        );

        localStorage.setItem(
          "userId",
          JSON.stringify(response.data.userObject.userId)
        );

        localStorage.setItem(
          "membershipId",
          JSON.stringify(response.data.userObject.membershipId)
        );

        if (rememberMe === true) {
          localStorage.setItem("rememberMe", JSON.stringify(rememberMe));

          const encryptedEmail = CryptoJS.AES.encrypt(
            formData.email,
            encryptionKey
          ).toString();
          const encryptedPassword = CryptoJS.AES.encrypt(
            formData.password,
            encryptionKey
          ).toString();

          localStorage.setItem(
            "loginDetails",
            JSON.stringify({
              email: encryptedEmail,
              password: encryptedPassword,
            })
          );
        }

        if (response.data.userObject.role) {
          setupSocket();
          setMobileNav(false);
          if (response.data.userObject.role === "user") {
            navigate("/user-dashboard");
          } else {
            navigate("/admin-dashboard");
          }
        }
      })
      .catch(function (err) {
        console.log(err);
        notify(err.response.data.message);
        setIsButtonDisabled(false);
      });
  };

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    setMobileNav(true);
    const rememberMe = JSON.parse(localStorage.getItem("rememberMe"));
    if (rememberMe === true) {
      const loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
      const decryptedEmail = CryptoJS.AES.decrypt(
        loginDetails.email,
        encryptionKey
      ).toString(CryptoJS.enc.Utf8);
      const decryptedPassword = CryptoJS.AES.decrypt(
        loginDetails.password,
        encryptionKey
      ).toString(CryptoJS.enc.Utf8);

      setFormData({
        email: decryptedEmail,
        password: decryptedPassword,
      });

      setRememberMe(true);
    }
  }, []);

  return (
    <div className="  bg-[#F5FAFF] py-16 ">
      <div className=" bg-white max-w-xl mx-auto rounded-xl py-10 px-16 login-container">
        <div className="company-logo flex items-center justify-center mb-6">
          <img src={Logo} alt="ANSTesters-Logo" className="w-[150px]" />
        </div>
        <div className="space-y-2">
          <p className=" text-2xl font-semibold login-heading">Login To Your Account</p>
          <p className="text-lg font-poppins login-sub-heading">
            We are excited to have you back.
          </p>
        </div>

        <form className=" mt-9  flex flex-col" onSubmit={handleSubmit}>
          <div className="space-y-6 flex flex-col">
            <Input
              id="email"
              label="Email Address Or Phone Number"
              type="text"
              placeholder="myself@myemail.com"
              name="email"
              value={formData.email || ""}
              handleChange={handleChange}
            />
            <div className="relative">
            <Input
              id="password"
              label="Password"
              type={passwordShow ? "text" : "password"}
              placeholder="Enter your password"
              name="password"
              value={formData.password || ""}
              handleChange={handleChange}
             />
              <button onClick={() => setPasswordShow(!passwordShow)} type="button" className=" absolute right-4 bottom-3"> <img src={passwordShow ? View : Private} className=" w-6 h-6"/> </button>
              </div>
            <div className="flex justify-between remember-me-container">
              <div className="flex space-x-2 items-center">
                <input
                  id="remember-me"
                  checked={rememberMe}
                  type="checkbox"
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <label className=" cursor-pointer" htmlFor="remember-me">
                  Remember me
                </label>
              </div>

              <Link
                href="/"
                to={"/forgot-password"}
                className="text-[#2D4F93] cursor-pointer"
              >
                Forgot Password?
              </Link>
            </div>
          </div>

          <Button
            disabled={isButtonDisabled}
            classname={`mt-6`}
            children={isButtonDisabled ? "Loading..." : "Login"}
          ></Button>
        </form>

        <div className=" text-center my-20 new-user">
          <span>
            New user?{" "}
            <Link className="text-[#2D4F93]" to={"/sign-up"}>
              Sign Up
            </Link>
          </span>
        </div>
      </div>

      <div className="absolute flex justify-center bottom-8 w-[100vw] text-sm">
        <p>
          Powered and donated by{" "}
          <Link to={"https://www.raoatech.com"} className="text-[#2D4F93]">
            Raoatech
          </Link>
        </p>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Login;
