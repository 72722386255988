import SideNavBar from "../../components/side-nav-bar";
import Discussion from "../../components/discussion";
import EventCalender from "../../components/events-calendar";
import ResourceLibrary from "../../components/admin-dashboard-resource-library";
import '../../styles/global.scss'
import '../../styles/user-dashboard.scss'

const User = () => {
  return (
    <div className=" flex space-x-4 text-[#000000] mobile-mt">
      <SideNavBar />

      <div className="w-[75%] flex space-x-3 pr-10 py-5 user-dashboard-container">
        <div className="w-[70%] space-y-4 event-resource">
          <EventCalender />
          <ResourceLibrary />
        </div>

        <div className="w-[30%] discussion">
          <Discussion />
        </div>
      </div>
    </div>
  );
};
export default User;
