import { useState, useEffect } from "react";
import SideNavBar from "../../components/side-nav-bar";
import Footer from "../../components/footer";
import ExistingNominee from "../../components/ExistingNominee";
import { axiosInstance } from "../../utils/apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ExistingVoting = () => {
  const [votesData, setVotesData] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedVotingData, setSelectedVotingData] = useState(null);
  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    const fetchVotes = async () => {
      try {
        const response = await axiosInstance.get("/vote/polls", {
          headers: {
            Authorization:
            `Bearer ${JSON.parse(localStorage.getItem("datakey"))}`,
          },
        });
        const data = response.data;
        setVotesData(data);
      } catch (error) {
        notify(error.response.data.message);
      }
    };

    fetchVotes();
  }, []);

  useEffect(() => {
    // Find the selected data based on the selected title
    const selectedData = votesData?.find(
      (vote) => vote.title === selectedTitle
    );
    setSelectedVotingData(selectedData);
  }, [votesData, selectedTitle]);

  // Event handler for select tag
  const handleSelectChange = (event) => {
    setSelectedTitle(event.target.value);
  }

  return (
    <>
      <div className="w-screen flex flex-col lg:flex-row lg:space-x-4 text-[#000000] ">
        <SideNavBar />

        <div className="flex flex-col px-5 py-10  max-h-screen w-full">
          <div className="flex flex-col border-2 border-gray-200 pl-11 overflow-auto">
            <h2 className="text-4xl font-medium  mt-7">
              List of All Existing Voting
            </h2>

            <div className="my-10  w-1/2">
              {votesData && (
                <select value={selectedTitle} onChange={handleSelectChange}>
                  <option value="">--Select an Existing Vote--</option>
                  {votesData.map((data) => (
                    <option value={data.title} key={data._id}>
                      {data.title}
                    </option>
                  ))}
                </select>
              )}
            </div>

            {/* Conditionally render UserImage components */}

            <div>
              <h2 className="text-2xl mb-8">{selectedTitle}</h2>
            </div>

            {selectedVotingData &&
              selectedVotingData.categories.map((category) => (
                <div key={category._id} className="mb-5">
                  <div className="bg-gray-200 p-3 mb-3">
                    <span className="mr-2 font-semibold">CATEGORY: </span>
                    <span>{category.categoryName}</span>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    {category.items.map((item) => (
                      <ExistingNominee nominee={item} key={item._id} categoryId={category._id} />
                    ))}
                  </div>
                  <p className="text-center my-3">
                    Total Votes: <span className="font-medium">0</span>
                  </p>
                </div>
              ))}

            {selectedVotingData && (
              <div className="text-right mr-10 pb-4">
                <button className=" bg-[#2D4F93] text-white px-3 py-2 rounded transition-all">
                  Print / Export
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer
        position="bottom-center"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ExistingVoting;
