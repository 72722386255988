export const currencyFormat = (
  currency = "NGN",
  amount = 0,
  region = "en-NG",
) => {
  return new Intl.NumberFormat(region, {
    style: "currency",
    currency,
  }).format(amount);
};

export const authentification = () => {
  const datakey = JSON.parse(localStorage.getItem("datakey"));
  const role = JSON.parse(localStorage.getItem("user-role"));
  const location = window.location.pathname;
  if(!datakey) {
    window.location.href = "/login";
  }

  if(role !== "admin" && location.includes("admin")) {
    window.location.href = "/user-dashboard";
  }
};

export const searchFunction = (arr, search, keys) => {
  return arr.filter((item) =>
    keys.some((key) =>
      item[key]?.toString().toLowerCase().includes(search.toLowerCase())
    )
  );
};