// import { useState, useEffect } from "react";
// import { axiosInstance } from "../utils/apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ExistingNominee = ({ nominee, categoryId }) => {
  // const [percentage, setPercentage] = useState("");
  // const notify = (text) => {
  //   return toast.info(text, {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  // };

  // useEffect(() => {
  //   const getCategoryPercentages = async () => {
  //     try {
  //       const response = await axiosInstance.get(
  //         `/vote/polls/total-percentage/${categoryId}`,
  //         {
  //           headers: {
  //             Authorization:
  //               "Bearer " + JSON.parse(localStorage.getItem("datakey")),
  //           },
  //         }
  //       );
  //       const data = response.data;
  //       console.log(data);
  //       setPercentage(data);
  //     } catch (error) {
  //       notify(error.response.data.message);
  //     }
  //   };
  //   getCategoryPercentages();
  // }, [categoryId]);

  return (
    <section className="rounded-lg border border-black py-3 px-5 flex flex-col justify-between items-start mb-2 ">
      <main className="flex justify-center items-center">
        <div className="w-[120px] h-[120px] bg-[#D9D9D9] rounded-[9px] overflow-hidden ">
          <img src={nominee.image} alt="" className="object-cover" />
        </div>
        <div className="ml-3">
          <span className="text-lg">{nominee.name}</span>
          <p className="opacity-60">{nominee.description}</p>
          <p className="text-gray-400 my-1">
            Score:{" "}
            <span className="px-1 font-semibold text-black">
              {nominee.votes}
            </span>
          </p>
          <p className="text-gray-400">
            Score in %:{" "}
            <span className="px-1 font-semibold text-black">
              {nominee.votes}
            </span>
          </p>
        </div>
      </main>
      <ToastContainer
        position="bottom-center"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
};

export default ExistingNominee;
