import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../utils/apiConfig";

const Discussion = () => {
  const role = JSON.parse(localStorage.getItem("user-role"));
  const [userJoinedGroups, setUserJoinedGroups] = useState([]);

  const Card = ({ group, name }) => {
    return (
      <div className=" bg-primary rounded-xl flex items-center space-x-3 py-4 px-5 capitalize">
        <div className=" bg-[#000000] rounded-full w-[40px] h-[40px]" />
        <div className="flex flex-col items-center">
          <p className=" text-base font-medium text-[#000000]">{group}</p>
          <p className=" text-xs text-[##EBF0F9]">{name}</p>
        </div>
      </div>
    );
  };

  const fetchUserGroups = () => {
    axiosInstance
      .get(`/discussion/user-groups`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      })
      .then((res) => {
        setUserJoinedGroups(res.data.joined_groups);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchUserGroups();
  }, []);

  const UIGroups = (groups) =>
    groups.length < 4 ? groups : [groups[0], groups[1], groups[2]];

  return (
    <div className="rounded-2xl bg-[#EBF0F9] px-4 py-5">
      <div className="flex justify-between mb-4 items-center">
        <p className="text-xl font-bold header-text">Discussion</p>

        <Link to="/user/discussion" className=" text-md text-[#D9D9D9]">
          See All
        </Link>
      </div>

      <div className=" space-y-3">
        {userJoinedGroups.length < 1 && (
          <p className="text-center p-8">You have not Joined any Groups</p>
        )}
        {userJoinedGroups.length > 0 &&
          UIGroups(userJoinedGroups).map((group) => (
            <Card group={group.group} name={group.name} />
          ))}
      </div>
    </div>
  );
};

export default Discussion;
