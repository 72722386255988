import SideNavBar from "../../components/side-nav-bar";
import Footer from "../../components/footer";
import Input from "../../components/input";
import Button from "../../components/button";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { axiosInstance } from "../../utils/apiConfig";

const Admin = () => {
  const [formData, setFormData] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    setIsButtonDisabled(true);
    axiosInstance
      .get("/users/get-user", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      })
      .then((res) => {
        var localDate = new Date(res.data.dob);

        var localDate = new Date(res.data.dob);

        setFormData(res.data);
        setIsButtonDisabled(false);
      })
      .catch((err) => {
        setIsButtonDisabled(false);
      });
  };

  const dateObj = new Date(formData.dob); // create a new date object from the UTC date

  const yyyy = dateObj.getUTCFullYear(); // get the year in UTC format
  const mm = dateObj.getUTCMonth() + 1; // get the month in UTC format (add 1 because getUTCMonth() returns a zero-based index)
  const dd = dateObj.getUTCDate(); // get the day in UTC format

  const formattedDate = `${yyyy}-${mm.toString().padStart(2, "0")}-${dd
    .toString()
    .padStart(2, "0")}`; // format the date as "yyyy-mm-dd"

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);

    axiosInstance
      .patch("/users/edit-profile/", formData, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      })
      .then(function (response) {
        notify(response.data.message);
        setFormData(formData);
        setFormData(formData);
        setIsButtonDisabled(false);
      })
      .catch(function (error) {
        notify(error.response.data.message);
        setIsButtonDisabled(false);
      });
  };

  return (
    <>
      <div className=" flex flex-col lg:flex-row lg:space-x-4 text-[#000000]">
        <SideNavBar />
        <form
          onSubmit={handleSubmit}
          className=" mt-9 lg:w-[80%] flex flex-col rounded-2xl bg-[#EBF0F9] px-4 py-5"
        >
          <div className="space-y-6 flex flex-col">
            <Input
              id="firstname"
              label="Firstname"
              name="first_name"
              type="text"
              placeholder="firstname"
              value={formData.first_name || ""}
              handleChange={handleChange}
            />
            <Input
              id="lastname"
              label="Lastname"
              name="last_name"
              type="text"
              placeholder="lastname"
              value={formData.last_name || ""}
              handleChange={handleChange}
            />
            <Input
              id="address"
              label="Address"
              name="address"
              type="text"
              value={formData.address || ""}
              placeholder="Address"
              handleChange={handleChange}
            />
            <Input
              id="employer"
              label="Employer"
              name="employer"
              type="text"
              value={formData.employer || ""}
              placeholder="name of employer"
              handleChange={handleChange}
            />
            <div className=" flex space-x-8">
              <Input
                id="phone-number"
                name="phone_number"
                label="Phone Number"
                type="text"
                value={formData.phone_number || ""}
                placeholder="Phone Number"
                handleChange={handleChange}
                className="w-[65%]"
              />

              <Input
                id="years_of_exp"
                label="Years of Experience"
                type="text"
                name="years_of_exp"
                placeholder="Years of Experience"
                value={formData.years_of_exp || ""}
                handleChange={handleChange}
                className="w-[35%]"
              />
            </div>

            <div className=" flex flex-col">
              <label className=" text-base md:text-xl font-normal label">
                Membership Type
              </label>
              <select
                className="border-2 px-3 py-3 md:px-4 md:py-3 text-sm md:text-base border-black rounded-md outline-none"
                value={formData.membership_type || ""}
                name="membership_type"
                onChange={handleChange}
              >
                <option value="">--Select option--</option>
                <option value="student">Student</option>
                <option value="corporate">Corporate</option>
                <option value="graduate">Graduate</option>
              </select>
            </div>
          </div>
          <Button
            disabled={isButtonDisabled}
            classname=" mt-14 "
            children={isButtonDisabled ? "Loading..." : "Save"}
          >
            Save
          </Button>
        </form>
      </div>
      <Footer />
      <ToastContainer
        position="bottom-center"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default Admin;
