import React from "react";
import { axiosInstance, baseURL } from "../utils/apiConfig";
import DownloadSvg from "../assets/svg/download.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const File = ({ fileinfo }) => {
  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const downloadFile = async (e) => {
    notify("Downloading File, Please wait");
    try {
      const response = await axiosInstance.get(
        `/users/download/${fileinfo._id}`,
        {
          responseType: "blob",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("datakey")),
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileinfo.filename}`);
      link.click();
      window.URL.revokeObjectURL(link);
    } catch (error) {
      notify("An error occured, Please try again");
    }
  };

  const previewImg = `${baseURL}/users/preview/${fileinfo._id}`;

  return (
    <div className="w-[30%] min-h-[215px] max-h-[215px] bg-[#f5f5f5] rounded mb-3 mr-3 border upload-item">
      <div className="h-[10.5rem]">
        <img
          className="h-[10.5rem] object-cover min-w-full"
          src={previewImg}
          alt="component-preview"
        />
      </div>
      <div className="flex items-center justify-between h-12 hover:bg-gray-200">
        <p className="font-medium text-sm truncate pl-3 w-3/4">
          {fileinfo.filename}
        </p>
        <img
          className="pr-3"
          src={DownloadSvg}
          onClick={downloadFile}
          alt="download-btn"
        />
      </div>
    </div>
  );
};

export default File;
