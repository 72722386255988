// import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useState } from "react";

function MyCalendar() {
  const [selectedDate, setSelectedDate] = useState(new Date());

  function handleDateChange(date) {
    setSelectedDate(date);
  }

  return (
    <div>
      <Calendar
        value={selectedDate}
        onChange={handleDateChange}
        className=" items-center mt-10 min-[320px]:w-[70%] "
      />
    </div>
  );
}

export default MyCalendar;
