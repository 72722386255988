const UserImage = ({ nominee, handleVote, selected }) => {
  return (
    <section className="rounded-lg border border-black pt-1 pb-5 px-5 flex flex-col justify-between items-start mb-2 ">
      <main
        className="flex justify-start items-start mt-3"
        onClick={handleVote}
      >
        <div className="w-[165px] h-[120px] bg-[#D9D9D9] rounded-[9px] overflow-hidden">
          <img
            src={nominee.image}
            alt=""
            className="object-cover object-center"
          />
        </div>
        <div className="ml-3">
          <span className="text-lg">{nominee.name}</span>
          <p className="opacity-60">{nominee.description}</p>
        </div>
      </main>
      <div className="mt-2 ml-4 flex justify-center items-center">
        <label htmlFor="voting" className="mr-5">
          Click to vote
        </label>
        <input
          type="radio"
          name={`voting-${nominee._id}`}
          checked={selected}
          onChange={handleVote}
        />
      </div>
    </section>
  );
};

export default UserImage;
