import SideNavBar from "../../components/side-nav-bar";
import Footer from "../../components/footer";
import Certificate from "../../components/certificate";
import html2pdf from "html2pdf.js";
import Download from "../../assets/images/download.png";
import { Link } from "react-router-dom";
import View from "../../assets/images/vision.png";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import Certificateview from "../../components/certificateview"; // Replace with the actual path to your CertificateComponent

const downloadAsPDF = () => {
  const componentToConvert = document.getElementById("cert");
  html2pdf().from(componentToConvert).save();
};

const AdminCertificate = () => {
  const urlToShare = "https://portal-anstesters.netlify.app/certificate"; // Replace with the URL of your certificate component

  return (
    <>
      <div className="w-screen flex flex-col lg:flex-row lg:space-x-4 text-[#000000]">
        <SideNavBar />

        <div className="flex flex-col space-y-10 px-10 py-20 max-h-screen w-4/5">
          <div className="flex flex-col space-y-3 p-2 border-solid border-2 border-gray-200 min-h-[600px] max-h-full overflow-auto justify-center items-center">
            <div className="w-[80%]">
              <Certificate />
            </div>
          </div>

          <div className="flex space-x-3">
            {/* for the download function. */}
            <section className="">
              <button onClick={downloadAsPDF}>
                <img
                  src={Download}
                  alt="Download"
                  width={40}
                  height={40}
                  title="Download"
                ></img>
              </button>
            </section>
            <section>
              {/* for the view function. */}
              <button>
                <Link to="/certificate">
                  <img
                    src={View}
                    alt="View certificate"
                    width={40}
                    height={40}
                    title="View certificate"
                  ></img>
                </Link>
              </button>
            </section>
            <section>
              <FacebookShareButton url={urlToShare} title="Share on Facebook">
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              <TwitterShareButton url={urlToShare} title="Share on Twitter">
                <TwitterIcon size={32} round />
              </TwitterShareButton>

              <WhatsappShareButton url={urlToShare} title="Share on WhatsApp">
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>

              <LinkedinShareButton url={urlToShare} title="Share on Linkedin">
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminCertificate;
