import { useRef, useState, useEffect } from "react";
import addImage from "../assets/images/add-image.png";

const Nominee = ({ name, description,image, onChange }) => {
   const [Image, setImage] = useState(image);
  const [preview, setPreview] = useState();
  const fileInputRef = useRef();

  useEffect(() => {
    if (Image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(Image);
    } else {
      setPreview(null);
    }
  }, [Image]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.substring(0, 5) === "image" && file.size <= 1048576) {
      setImage(file)
      onChange(name, description, file);
    } else {
       setImage(null);
    }
  };

  return (
    <section className="rounded-lg border border-black py-[13px] px-5 flex mb-6">
      <form className="flex">
        <div className="w-[90px] h-[73px] bg-[#D9D9D9] rounded-[9px] flex justify-center items-center hover:opacity-80 overflow-hidden">
          {preview ? (
            <img
              src={preview}
              alt="preview"
              className="object-cover"
              onClick={() => {
                 setImage(null);
              }}
            />
          ) : (
            <button
              type="button"
              onClick={() => {
                fileInputRef.current.click();
              }}
            >
              <img
                src={addImage}
                alt="add icon"
                className="max-w-[100%] block w-8"
              />
            </button>
          )}

          <input
            type="file"
            hidden
            accept="image/*"
            ref={fileInputRef}
            onChange={handleImageChange}
          />
        </div>
        <div className="ml-3 flex flex-col">
          <input
            type="text"
            placeholder="Name"
            className="border-none mb-2"
            value={name}
            onChange={(e) => onChange(e.target.value, description, image)}
            name={name}
          />
          <textarea
            id=""
            cols="0"
            rows="0"
            placeholder="Description"
            className="border-none"
            maxLength="100"
            value={description}
            onChange={(e) => onChange(name, e.target.value, image)}
            name={description}
          ></textarea>
        </div>
      </form>
    </section>
  );
};

export default Nominee;
