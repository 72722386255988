import Input from "../../components/input";
import Button from "../../components/button";
import {axiosInstance} from "../../utils/apiConfig";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [formData, setFormData] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);

    axiosInstance
      .post(
        "/users/forgot-password",
        formData
      )
      .then((response) => {
        notify(response.data.message);
        setIsButtonDisabled(false);
      })
      .catch((error) => {
        notify(error.response.data.message);
        setIsButtonDisabled(false);
      });
  };

  return (
    <div className=" h-screen bg-[#F5FAFF] py-16  ">
      <div className=" bg-white max-w-xl mx-auto rounded-xl pt-10 pb-20 px-16">
        <div className="space-y-2">
          <p className=" text-2xl font-semibold">Forgot Password</p>
          <p className="text-lg font-poppins">
            Enter your email to reset password?
          </p>
        </div>

        <form onSubmit={handleSubmit} className=" mt-9 space-y-8 flex flex-col">
          <Input
            id="email"
            label="Email Address."
            type="text"
            placeholder="Password"
            handleChange={handleChange}
            name="email"
            value={formData.email}
          />

          <Button
            disabled={isButtonDisabled}
            children={isButtonDisabled ? "Loading..." : "Submit"}
            classname=""
          >
            Submit
          </Button>
        </form>
        <div className=" flex justify-between mt-32">
          <span>Didn’t get a mail?</span>
          <p className=" cursor-pointer text-[#2D4F93]" onClick={handleSubmit}>
            Request Again.
          </p>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ForgotPassword;
