import SideNavBar from "../../components/side-nav-bar";
import RightSide from "../layout/right-side";
import Footer from "../../components/footer";
import '../../styles/global.scss'

const Admin = () => {
  return (
    <>
      <div className=" flex flex-col lg:flex-row lg:space-x-4 text-[#000000]">
        <SideNavBar />
        <RightSide />
      </div>
      <Footer />
    </>
  );
};
export default Admin;
