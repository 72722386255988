import { useState, useEffect } from "react";
import SideNavBar from "../../components/side-nav-bar";
import Footer from "../../components/footer";
import UserNominee from "../../components/UserNominee";
import Button from "../../components/button";
import { axiosInstance } from "../../utils/apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserVoting = () => {
  const [votesData, setVotesData] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedVotingData, setSelectedVotingData] = useState(null);
  const [votesArray, setVotesArray] = useState([]);
  const [endDate, setEndDate] = useState("");

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axiosInstance.get("/vote/polls", {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("datakey")),
          },
        });
        setVotesData(data);
      } catch (error) {
        notify(error.data);
      }
    };
    const selectedData = votesData?.find(
      (vote) => vote.title === selectedTitle
    );
    setSelectedVotingData(selectedData);
    fetchData();
  }, [selectedTitle, votesData]);

  useEffect(() => {
    const endDateConvert = new Date(
      selectedVotingData?.categories[0].endDate
    ).toDateString();
    setEndDate(endDateConvert);
  }, [selectedVotingData]);

  // Event handler for select tag
  const handleSelectChange = (event) => {
    const title = event.target.value;
    setSelectedTitle(title);
  };

  const handleVote = (categoryId, itemId) => {
    // Check if a vote for the selected category already exists
    const existingVoteIndex = votesArray.findIndex(
      (vote) => vote.categoryId === categoryId
    );

    if (existingVoteIndex !== -1) {
      // If a vote for this category already exists, update the selection
      setVotesArray((prevVotes) => {
        const updatedVotes = [...prevVotes];
        updatedVotes[existingVoteIndex] = { itemId, categoryId };
        return updatedVotes;
      });
    } else {
      // If no vote for this category exists, add a new vote to votesArray
      setVotesArray((prevVotes) => [...prevVotes, { itemId, categoryId }]);
    }
  };

  // Calculate if all categories have votes
  const isAllCategoriesVoted =
    selectedVotingData?.categories?.every((category) =>
      votesArray.some((vote) => vote.categoryId === category._id)
    ) || false;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.put(
        `/vote/polls/${selectedVotingData._id}/vote`,
        { votesArray },
        {
          headers: {
            Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
          },
        }
      );
      console.log(response.data.message);
      notify(response.data.message);
    } catch (error) {
      notify(error.response.data.message);
    }
  };

  // console.log(selectedVotingData);
  // console.log(votesData)

  return (
    <>
      <div className="flex flex-col lg:flex-row lg:space-x-4 text-[#000000] ">
        <SideNavBar />

        <div className="flex flex-col px-5 py-10  max-h-screen w-full">
          <form
            className="flex flex-col border-2 border-gray-200 pl-11 overflow-auto"
            onSubmit={handleSubmit}
          >
            <h2 className="text-4xl font-medium  mt-7">Voting</h2>

            <div className="my-10 flex justify-between items-center">
              <div>
                <h3 className="mb-2">Recent Votings</h3>
                {votesData && (
                  <select value={selectedTitle} onChange={handleSelectChange}>
                    <option value="">--Select a title--</option>
                    {votesData.map((data) => (
                      <option value={data.title} key={data._id}>
                        {data.title}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              <div className="mr-11">
                <h3>End Date</h3>
                <span className="text-lg text-[#2D4F93]">
                  {endDate === "Invalid Date" ? "" : endDate}
                </span>
              </div>
            </div>

            {/* Conditionally render UserImage components */}

            {selectedVotingData &&
              selectedVotingData.categories.map((category) => (
                <div key={category._id} className="mb-5">
                  <div className="bg-gray-200 p-3 mb-3">
                    <span className="mr-2 font-semibold">CATEGORY: </span>
                    <span key={category._id}>{category.categoryName}</span>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    {category.items.map((item) => (
                      <UserNominee
                        nominee={item}
                        key={item._id}
                        // checking for the selected nominee
                        selected={votesArray.some(
                          (vote) =>
                            vote.categoryId === category._id &&
                            vote.itemId === item._id
                        )}
                        handleVote={() => handleVote(category._id, item._id)}
                      />
                    ))}
                  </div>
                </div>
              ))}

            <div className="w-full flex justify-center my-12">
              {/* If selected title is clicked or true or selected then show this button*/}
              {selectedTitle && (
                <Button
                  classname={"w-[50%]"}
                  type="submit"
                  disabled={!isAllCategoriesVoted}
                >
                  Submit
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
      <Footer />
      <ToastContainer
        position="bottom-center"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default UserVoting;
