import SideNavBar from "../../components/side-nav-bar";
import MyCalendar from "../../components/calendar";
import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../utils/apiConfig";
//import axios from 'axios';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';

const EventCalendar = () => {
  const [eventList, setEventList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const getEventList = () => {
    axiosInstance
      .get("/users/user-events", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      })
      .then(function (response) {
        setEventList(response.data);
      })
      .catch(function (err) {});
  };

  useEffect(() => {
    getEventList();
  }, []);

  return (
    <>
      <div className=" h-screen w-full flex flex-col lg:h-screen lg:flex lg:flex-row lg:space-x-3 min-[320px]:h-screen min-[320px]:w-full">
        <SideNavBar />
        <div className=" min-[320px]:w-full min-[320px]:h-screen min-[320px]:mt-5 lg:w-[70%] border-2 mt-10">
          <div className=" text-3xl mt-10 ml-5">Event Calender</div>
          <div className=" bg-[#F7F9FD] min-[320px]:w-[30%] min-[320px]:item-center lg:w-[90%] lg:rounded-2xl px-2 py-3 ml-9 mt-4 pr-8 space-x-36 flex justify-center items-center text-center">
            <span className=" rounded-3xl w-[40%] text-lg font-medium px-3 py-2 text-center  min-[320px]:pr-4">
              Upcoming Events
            </span>
          </div>
          <div className=" m-10 ml-2">
            <table className="block max-h-[700px] overflow-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full">
              <thead className="block sticky top-0">
                <tr className="flex space-x-5 mb-4">
                  <th className="min-w-[64px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-2xl">
                    S/N
                  </th>
                  <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
                    Name of Event
                  </th>
                  <th className=" min-w-[350px] max-w-[400px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
                    Venue
                  </th>
                  <th className=" min-w-[350px] max-w-[400px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
                    Members
                  </th>
                  <th className=" min-w-[350px] max-w-[400px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
                    Start date
                  </th>
                  <th className=" min-w-[350px] max-w-[400px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
                    End date
                  </th>
                </tr>
              </thead>
              <tbody className="block sticky top-0">
                {eventList.map((item, index) => (
                  <tr key={item.id} className="flex space-x-5 mb-4">
                    <td className="min-w-[64px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-2xl">
                      {index + 1}
                    </td>
                    <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
                      {item.name}
                    </td>
                    <td className=" min-w-[350px] max-w-[400px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
                      {item.venue}
                    </td>
                    <td className=" min-w-[350px] max-w-[400px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
                      {item.members}
                    </td>
                    <td className=" min-w-[350px] max-w-[400px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
                      {item.start_date}
                    </td>
                    <td className=" min-w-[350px] max-w-[400px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
                      {item.end_date}
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* <tbody>
                {data.map((item, index) => (
                <tr 
                  key={index.id}>
                  <td>{item.name}</td>
                  <td>{item.venue}</td>
                /tr>
              ))}
              </tbody> */}
            </table>
          </div>
        </div>
        {/* <div className=" h-full border-2  min-[320px]:w-full min-[320px]:h-screen min-[320px]:mt-5 w-[23%] items-center flex flex-col justify-center space-y-20">
          <div className="">
            <MyCalendar onSelect={handleDateSelect} />
          </div>
          <div>
            <button onClick={handleSearch} className=" rounded-2xl border-2 p-2 px-7 justify-center items-center">
              Search for event
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default EventCalendar;
