import React from "react";
import { useState, useEffect } from "react";
import { axiosInstance } from "../../../utils/apiConfig";
import { currencyFormat } from "../../../utils/reusables";
import SearchBar from "../../../components/searchbar";
import SideNavBar from "../../../components/side-nav-bar";
import Footer from "../../../components/footer";
import { searchFunction } from "../../../utils/reusables";
import '../../../styles/admin-bills.scss'

function AdminBillHistory() {
  const [search, setSearch] = useState("");
  const [bills, setBills] = useState([]);

  const SingleBillComp = ({ name, amount, date }) => {
    return (
      <div className="flex my-4 bill-history-table-body">
        <div className="w-[50%]">
          <p className="text-lg max-w-[90%] truncate whitespace-nowrap capitalize">
            {name}
          </p>
        </div>
        <div className="w-[35%]">
          <p className="text-lg">{amount}</p>
        </div>
        <div className="w-[15%]">
          <p className="text-lg">{date}</p>
        </div>
      </div>
    );
  };

  const getAllBills = async () => {
    try {
      const response = await axiosInstance.get("/admin/created-bills", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      });

      setBills(response.data.reverse());
    } catch (err) {}
  };

  const searchKeys = ["bill_name", "bill_amount", "createdAt"];

  const handleSearch = (find) => {
    setSearch(find);
  };

  useEffect(() => {
    getAllBills();
  }, []);

  useEffect(() => {}, [bills]);

  return (
    <>
      <div className="max-w-screen flex flex-col lg:flex-row lg:space-x-4 text-[#000000] mobile-mt">
        <SideNavBar />

        <div className="flex flex-col space-y-10 px-10 py-20 w-screen md:w-4/5 bill-history-container">
          {/* Search Bar */}
          <SearchBar onSearch={handleSearch} />

          <div className="w-full border-solid border-2 border-gray-200 h-full p-6 flex flex-col bill-history-content-container">
            <h1 className="font-semibold text-4xl">List Of All Bills</h1>
            <h2 className="text-xl mt-2">Display a list of all bills</h2>

            {/* Table container */}
            <div className="flex flex-col space-y-4 mt-10 pb-2 mb-3 px-2 border-b-2 border-grey-200 bill-history-table-head">
              {/* Table head */}
              <div className="flex">
                <div className="w-[50%]">
                  <h1 className="font-semibold text-lg">Name</h1>
                </div>
                <div className="w-[35%]">
                  <h1 className="font-semibold text-lg">Amount</h1>
                </div>
                <div className="w-[15%]">
                  <h1 className="font-semibold text-lg">Created At</h1>
                </div>
              </div>
            </div>

            {/* Table body */}
            <div className="flex flex-col px-2 max-h-[60vh] overflow-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full table-body-container">
              {searchFunction(bills, search, searchKeys).map((bill) => (
                <SingleBillComp
                  key={bill.id}
                  name={bill.bill_name}
                  amount={currencyFormat("NGN", bill.bill_amount)}
                  date={bill.createdAt.split("T")[0]}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AdminBillHistory;
