// import background from "../assets/images/logo.jpeg";
import watermark from "../assets/images/watermark.png";
import signature from "../assets/images/signature.png";
import Background from "../assets/images/ANSTesters-black-removebg.png";
import Background1 from "../assets/images/background1.png";
import QRcodeSVG from "../assets/svg/QRcode.svg";
import "./certificate.css";
import { useEffect, useState } from "react";
import { axiosInstance } from "../utils/apiConfig";
import React from "react";
//import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

const Certificate = () => {
  const [certData, setCertData] = useState({});

  const fetchCert = () => {
    axiosInstance
      .get("/users/get-cert", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")), //to get the token of who logged in
        },
      })
      .then((res) => {
        setCertData(res.data);
      })
      .catch((error) => {
        if (
          error.response.data.error === "You have not paid for certificate yet"
        ) {
          notify("You have not paid for certificate ");
        }
      });
  };

  useEffect(() => {
    fetchCert();
  }, []);

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  return (
    <>
      <div className="flex justify-center items-center flex-col ">
        <div
          id="cert"
          className="  border-4 border-blue-500 mt-28 w-[60%] lg:w-full lg:h-[60%] min-[661px]  md:w-[95%] md:h-[80%] relative items-center justify-center"
        >
          <section className="flex justify-center space-x-8 mt-5 md:space-x-2">
            <img
              src={Background}
              alt="Anstesters"
              width="140"
              length="140"
              className=" md:pl-5"
            ></img>
            <span className=" text-2xl bg-gradient-to-r from-cyan-400 to-cyan-700 text-transparent bg-clip-text">
              Association of Nigerian Software Testers
            </span>
          </section>

          <section>
            <section className=" absolute top-0 left-0 -z-20 w-[80vw] h-[80vh] flex justify-center items-center pb-48 pr-48 md:ml-36 ">
              <img
                src={Background1}
                alt=""
                className="lg:w-[56%] lg:h-[40%] md:w-full md:h-[60%] lg:mr-80 mb-16 "
              ></img>
            </section>

            <section className=" flex flex-col items-center z-10 md:text-sm ">
              <span>This is to certify</span>
              <span className=" text-3xl lg:p-3 capitalize">
                <b>{certData?.name || "---"}</b>
              </span>
              <span>as a member of the</span>
              <span>
                Association of Nigeria Software testers,a non-profit
                organisation that is dedicated to
              </span>
              <span>
                quality assurance and software testing community in Nigeria and
                advancement of software
              </span>
              <span>resolving/quality assurance profession</span>
            </section>

            <section className="flex lg:space-x-52 lg:pt-2 md:space-x-32">
              <div>
                {" "}
                <img src={watermark} alt="" width="100" height="120"></img>
              </div>

              <div className="flex flex-col justify-center items-center pt-5 ">
                <img src={signature} width="80" height="80"></img>
                <hr className="w-[90%] border-black border "></hr>
                <label for="president">Oludare Olarewaju,President</label>
              </div>
            </section>
          </section>

          <section className=" space-y-5 items-center justify-center">
            <div className="  flex justify-center items-center space-x-7 lg:space-x-20 lg:pl-24 md:space-x-20">
              <div className="">
                <label for="president1">Date issued:</label>
                <input
                  name="president1"
                  type="text"
                  className=" border-t-0  border-l-0 border-r-0 border-b-2 bg-transparent md:w-44 pointer-events-none"
                  value={certData?.issuedDate?.split("T")[0] || ""}
                ></input>
              </div>

              <div className=" w-">
                <label for="president">Expiry date</label>
                <input
                  name="president"
                  type="text"
                  className=" border-t-0  border-l-0 border-r-0 border-b-2 bg-transparent md:w-44 pointer-events-none"
                  value={certData?.validUntil?.split("T")[0] || ""}
                ></input>
              </div>
            </div>

            <div className=" flex flex-row lg:space-x-64 md:space-x-64 md:pb-4">
              <div className=" pl-10 lg:pl-32 lg:pb-9 md:ml-8 xl:pl-20">
                <label for="president2">Membership ID:</label>
                <input
                  name="president2"
                  type="text"
                  className=" border-t-0  border-l-0 border-r-0 border-b-2 md:w-44 pointer-events-none"
                  value={certData?.membership_id || ""}
                ></input>
              </div>
              <div className=" w-16 h-16">
                <img src={QRcodeSVG}></img>
              </div>
            </div>
          </section>
        </div>

        <ToastContainer
          position="bottom-center"
          autoClose={9000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};
export default Certificate;
