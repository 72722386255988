const Input = ({
  label,
  placeholder,
  name,
  id,
  type,
  handleChange,
  value,
  className,
  min,
}) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <label className=" text-base md:text-xl font-normal label" htmlFor={id}>
        {label}
      </label>
      <input
        className=" border-2 px-3 py-3 md:px-4 md:py-3 text-sm md:text-base border-black rounded-md outline-none"
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        min={min}
      />
    </div>
  );
};

export default Input;
