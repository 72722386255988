import React from "react";

const UserInfoTableItem = ({
  index,
  firstname,
  lastname,
  email,
  address,
  phonenumber,
  dob,
}) => {
  return (
    <tr className="flex space-x-5 mb-4">
      <td className="min-w-[64px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l font-bold">
        {index + 1}
      </td>

      <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
        {firstname}
      </td>

      <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
        {lastname}
      </td>

      <td className="min-w-[300px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
        {email}
      </td>

      <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
        {address}
      </td>

      <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
        {phonenumber}
      </td>

      <td className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
        {dob}
      </td>

      <td className="min-w-[150px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center">
        {/* Two Icons */}
      </td>
    </tr>
  );
};

const Table = ({ users }) => {
  return (
    <table className="block max-h-[700px] overflow-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full">
      <thead className="block sticky top-0">
        <tr className="flex space-x-5 mb-4">
          <th className="min-w-[64px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-2xl">
            S/N
          </th>

          <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
            First Name
          </th>

          <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
            Last Name
          </th>

          <th className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
            Email Address
          </th>

          <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
            Address
          </th>

          <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
            Phone Number
          </th>

          <th className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
            Date Of Birth
          </th>

          <th className="min-w-[150px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
            {/* Two Icons */}
          </th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <UserInfoTableItem
            key={index}
            index={index}
            firstname={user.first_name}
            lastname={user.last_name}
            email={user.email}
            address={user.address}
            phonenumber={user.phone_number}
            dob={user.dob}
          />
        ))}
      </tbody>
    </table>
  );
};

export default Table;
