import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Admin from "./admin/pages/admin";
import SignUp from "./user/pages/sign-up";
import Login from "./user/pages/login";
import User from "./user/pages/user";
import UserResourceLibrary from "./user/pages/resource-library";
import Downloads from "./user/pages/downloads";
import UserUploadResource from "./user/pages/upload-resource";
import ResetPassword from "./user/pages/reset-password";
import ForgotPassword from "./user/pages/forgot-password";
import MemberProfile from "./admin/pages/members-profile";
import ResourcesLibrary from "./admin/pages/resource-library/ResourcesLibrary";
import UploadResource from "./admin/pages/upload-resource";
import Discussion from "./user/pages/discussion";
import UserBills from "./user/pages/bills/bills";
import AdminBillsHistory from "./admin/pages/bills/BillHistory";
import PaymentReport from "./admin/pages/bills/PaymentReport";
import CreateNewBill from "./admin/pages/bills/CreateNewBill";
import Eventcalender from "./admin/pages/events-calendar";
import AdminCertificate from "./admin/pages/AdminCertificate";
import UserCertificate from "./user/pages/UserCertificate";
import AdminVoting from "./admin/pages/AdminVoting";
import UserVoting from "./user/pages/UserVoting";
import ExistingVotes from "./admin/pages/existingVotes";
import PageNotFound from "./admin/layout/404";
import "./App.css";
import Editprofile from "./user/pages/edit-profile";
import Verification from "./user/pages/email-verification";
import EventCalendar from "./user/pages/event-calendar";
import io from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
// import Certificate from "./components/certificate";
import CertificateView from "./components/certificateview";
import { baseURL } from "./utils/apiConfig";
import UserPaymentReport from "./user/pages/bills/UserPaymentReport";
import PaymentReceipt from "./user/pages/bills/PaymentReceipt";
import MobileNav from "./sharedComponents/MobileNav";

function App() {
  const [socket, setSocket] = useState(null);
  let currentToastId = null;
  const location = useLocation();
  const hideMobileNav = [
    "/sign-up",
    "/login",
    "/forgot-password",
    "/reset-password",
    "/",
  ].includes(location.pathname);

  const [mobileNav, setMobileNav] = useState(hideMobileNav);
  const notify = (text) => {
    if (currentToastId !== null) {
      // dismiss ongoing notification
      toast.dismiss(currentToastId);
    }
    const toastOptions = {
      toastId: uuidv4(), // generate unique ID
      position: toast.POSITION.TOP_RIGHT,
    };

    const toastElement = toast.info(text, toastOptions);
    currentToastId = toastOptions.toastId; // update current ID

    // setTimeout(() => {
    //   toast.dismiss(currentToastId);
    //   currentToastId = null; // reset current ID
    // }, 3000);

    return toastElement;
  };

  const setupSocket = async () => {
    const token = JSON.parse(localStorage.getItem("datakey"));
    if (token && !socket) {
      const newSocket = io(`${baseURL.split("/api")[0]}`, {
        transports: ["websocket"],
        withCredentials: true,
        extraHeaders: {
          "my-custom-header": "abcd",
        },
        query: {
          token: token,
        },
      });
      newSocket.on("connect", () => {
        // notify("Success: Socket Connected!");
      });
      newSocket.on("connect_error", (err) => {
        // notify(err);
      });
      newSocket.on("connect_timeout", (err) => {
        // notify(err);
      });
      newSocket.on("disconnect", () => {
        setSocket(null);
        setTimeout(setupSocket, 3000);
        // notify("Error: Socket Disconnected!");
      });
      setSocket(newSocket);
    }
  };

  return (
    <div className=" font-poppins">
      {!mobileNav && <MobileNav setMobileNav={setMobileNav} />}
      <Routes>
        {/* Login and Signup */}
        <Route path="/sign-up" element={<SignUp />} />
        <Route
          path="/"
          element={
            <Login setupSocket={setupSocket} setMobileNav={setMobileNav} />
          }
        />
        <Route
          path="/login"
          element={
            <Login setupSocket={setupSocket} setMobileNav={setMobileNav} />
          }
        />

        {/* Forgot and reset password */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Admin Routes */}
        <Route path="/admin-dashboard" element={<Admin />} />
        <Route path="/admin/voting/create-votes" element={<AdminVoting />} />
        <Route path="/admin/voting/existingVotes" element={<ExistingVotes />} />
        <Route path="/admin/certificate" element={<AdminCertificate />} />
        <Route path="/admin/event-calendar" element={<Eventcalender />} />
        <Route path="/admin/resource-library" element={<ResourcesLibrary />} />
        <Route path="/edit-profile" element={<Editprofile />} />
        <Route path="/member-profile" element={<MemberProfile />} />
        <Route
          path="/admin/bills/create-new-bill"
          element={<CreateNewBill />}
        />
        <Route path="/admin/bills/history" element={<AdminBillsHistory />} />
        <Route path="/admin/bills/payment-report" element={<PaymentReport />} />
        <Route path="/certificate" element={<CertificateView />} />

        {/* User Routes */}
        <Route
          path="/user/resource-library/recent-uploads"
          element={<UserResourceLibrary />}
        />
        <Route path="/user-dashboard" element={<User />} />
        <Route
          path="/user/resource-library/downloads"
          element={<Downloads />}
        />
        <Route
          path="/user/discussion"
          element={<Discussion socket={socket} setupSocket={setupSocket} />}
        />
        <Route path="/email-verification" element={<Verification />} />
        <Route path="/user/event-calendar" element={<EventCalendar />} />
        <Route path="/user/bills/pending-bills" element={<UserBills />} />
        <Route
          path="/user/bills/payment-report"
          element={<UserPaymentReport />}
        />
        <Route path="/user/bills/receipt" element={<PaymentReceipt />} />
        <Route path="/user/voting" element={<UserVoting />} />
        <Route path="/user/certificate" element={<UserCertificate />} />

        <Route
          path="*"
          element={<PageNotFound setMobileNav={setMobileNav} />}
        />
      </Routes>

      <ToastContainer
        position="bottom-center"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
