import SideNavBar from "../../components/side-nav-bar";
import Input from "../../components/input";
import Button from "../../components/button";
import { axiosInstance } from "../../utils/apiConfig";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Eventcalendar = () => {
  const [formData, setFormData] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [eventList, setEventList] = useState([]);

  // const currentDateAndTime = new Date().toISOString().slice(0, 16);
  let x = new Date().getTimezoneOffset() * 60000;
  const currentDateAndTime = new Date(Date.now() - x)
    .toISOString()
    .slice(0, -8);

  const formatDateToLocal = (date) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Use 24-hour format
    };
    return date.toLocaleString(undefined, options);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getEventList = () => {
    axiosInstance
      .get("/admin/get-events", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      })
      .then(function (response) {
        notify(response.data.message);
        setEventList(response.data);
      })
      .catch(function (err) {
        notify(err.response.data.message);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);

    axiosInstance
      .post("/admin/add-event", formData, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      })
      .then(function (response) {
        setIsButtonDisabled(false);
        notify("Event Added Sucessfully!");
        getEventList(); //used to get the registered events
      })
      .catch(function (err) {
        notify(err.response.data.message);
        setIsButtonDisabled(false);
      });
    //to clear out the data
    setFormData({
      name: "",
      members: "",
      venue: "",
      start_date: "",
      end_date: "",
    });
  };

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    getEventList();
  }, []);

  return (
    <>
      <div className=" h-full lg:space-x-6 lg:flex lg:flex-row sm:flex-col sm:items-center sm:space-x-12">
        <SideNavBar />
        <div className=" h-screen border-2 w-[42%] sm:mt-14 sm:w-[80%] sm:items-center min-[320px]:w-full min-[320px]:mt-5">
          <div className=" text-3xl mt-10 ml-5 sm:text-2xl ">
            Event Calender
          </div>
          <form
            className=" flex flex-col justify-center m-9 "
            onSubmit={handleSubmit}
          >
            <Input
              id="name"
              label="Name of Event"
              name="name"
              value={formData.name || ""}
              handleChange={handleChange}
            />

            <div className="flex flex-col mt-5">
              <label className=" text-base md:text-xl font-normal label ">
                Members
              </label>
              <select
                className="block w-full border-2 md:px-4 md:py-3 mt-2 text-sm md:text-base border-black rounded-md focus:outline-none focus:shadow-outline bg-white"
                value={formData.members || ""}
                id="members"
                label="Members"
                name="members"
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="Student">Student</option>
                <option value="Corporate">Corporate</option>
                <option value="Graduate">Graduate</option>
              </select>
            </div>

            <Input
              id={"venue"}
              label={"Venue"}
              name="venue"
              value={formData.venue || ""}
              handleChange={handleChange}
              className={"mt-5"}
            />

            <div className="flex flex-row mt-5 min-[320px]:flex-col min-[320px]:space-x-0">
              <Input
                id="start_date"
                label={"Start Date"}
                type="datetime-local"
                name="start_date"
                value={formData.start_date || ""}
                min={formatDateToLocal(currentDateAndTime)}
                handleChange={handleChange}
                className=" sm:w-[45%] md:w-full lg:w-full"
              />

              <Input
                id="end_date"
                name="end_date"
                label="End Date"
                type="datetime-local"
                value={formData.end_date || ""}
                min={formData.start_date || ""}
                handleChange={handleChange}
                className=" sm:w-[45%] lg:w-full md:w-full min-[320px]:mt-5 min-[320px]:w-full min-[320px]:"
              />
            </div>

            <Button
              classname={" mt-20"}
              disabled={isButtonDisabled}
              children={isButtonDisabled ? "Loading..." : "Save"}
            ></Button>
          </form>
        </div>

        <div className=" lg:w-[32%] border-2 sm:w-[80%] sm:h-screen sm:mt-5 lg:h-ful">
          <div className="flex flex-col justify-center m-9 lg:px-2 sm:items-center max-h-[600px]">
            <div className=" bg-[#F7F9FD] rounded-3xl px-2 py-3 inline-block pr-8 space-x-2 "></div>

            <div className=" m-10 ml-2">
              <table className="block max-h-[500px] overflow-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full">
                <thead className="block sticky top-0">
                  <tr className="flex space-x-5 mb-4">
                    <th className="min-w-[64px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-2xl">
                      S/N
                    </th>

                    <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
                      Name of Event
                    </th>
                  </tr>
                </thead>
                <tbody className=" max-h-[80%] overflow-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full">
                  {eventList.map((item, index) => (
                    <tr key={item.id} className="flex space-x-5 mb-4">
                      <td className="min-w-[64px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-2xl">
                        {index + 1}
                      </td>
                      <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
                        {item.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ToastContainer
          position="bottom-center"
          autoClose={9000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};

export default Eventcalendar;
