import SideNavBar from "../../components/side-nav-bar";
import SearchBar from "../../components/searchbar";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import { axiosInstance } from "../../utils/apiConfig";
import { useState, useEffect } from "react";
import DownloadedSvg from "../../assets/svg/downloaded.svg";
import "../../styles/user-resource-library.scss";

const DownloadedFiles = () => {
  const [downloadedFiles, setDownloadedFiles] = useState([]);

  const downloaded = async () => {
    try {
      const { data } = await axiosInstance.get("/users/get-downloaded-files", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      });
      setDownloadedFiles(data.downloaded_files);
    } catch (error) {}
  };

  useEffect(() => {
    downloaded();
  }, []);

  return (
    <>
      <div className="w-screen flex flex-col lg:flex-row lg:space-x-4 text-[#000000] mobile-mt">
        <SideNavBar />

        <div className="flex flex-col space-y-10 px-10 py-20 max-h-screen w-4/5 user-downloads-container">
          <SearchBar />

          <div className="flex flex-col space-y-3 p-2 border-solid border-2 border-gray-200 min-h-[600px] max-h-full overflow-auto downloads-container">
            <h1 className="text-3xl font-medium border-b border-gray-300 py-2 pl-2 header-text">
              Downloads
            </h1>

            <div className="w-full flex items-start flex-wrap px-3 download-list-container">
              {downloadedFiles.map((file) => (
                <div className="min-w-[200px] max-w-[200px] min-h-[215px] max-h-[215px] bg-[#f5f5f5] rounded mb-3 mr-2 border download-item">
                  <div className="h-[10.5rem]">
                    <img
                      className="h-[10.5rem] object-cover"
                      src=""
                      alt="component-preview"
                    />
                  </div>
                  <div className="flex items-center justify-between h-12 hover:bg-gray-200">
                    <p className="font-medium text-sm truncate pl-3 w-3/4">
                      {file.filename}
                    </p>
                    <img
                      className="pr-3"
                      src={DownloadedSvg}
                      alt="download-btn"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="w-1/5 px-10 py-20 change-state-container">
          <div className="flex flex-col space-y-5 full border-solid border border-[#1e1d1d] h-full py-16 px-5 ">
            <Link
              to={"/user/resource-library/recent-uploads"}
              className="block text-lg font-medium"
            >
              Recent Uploads
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DownloadedFiles;
