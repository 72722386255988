import Input from "../../components/input";
import Button from "../../components/button";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { axiosInstance } from "../../utils/apiConfig";
import queryString from "query-string";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const location = useLocation();
  const { userId, token } = queryString.parse(location.search);
  const [formData, setFormData] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);

    axiosInstance
      .post(`/users/reset-password?userId=${userId}&token=${token}`, formData)
      .then((response) => {
        notify(response.data.message);
        setIsButtonDisabled(false);
      })
      .catch((error) => {
        notify(error.response.data.message);
        setIsButtonDisabled(false);
      });
  };

  return (
    <div className=" h-screen bg-[#F5FAFF] py-16 flex-col justify-center ">
      <div className=" bg-white max-w-xl mx-auto rounded-xl pt-10 pb-12 px-16">
        <div className="space-y-2">
          <p className=" text-2xl font-semibold">Reset Password</p>
          <p className="text-lg font-poppins">Enter your new password?</p>
        </div>

        <form
          onSubmit={handleSubmit}
          className=" mt-9 space-y-20 flex flex-col"
        >
          <Input
            id="email"
            label="New Password."
            type="password"
            placeholder="Password"
            handleChange={handleChange}
            name="password"
            value={formData.password}
          />

          <Button
            disabled={isButtonDisabled}
            classname={""}
            children={isButtonDisabled ? "Loading..." : "Reset Password"}
          >
            Reset Password
          </Button>
        </form>

        <div className="text-center my-16">
          <Link to={"/login"} className="text-[#2D4F93] cursor-pointer">
          Back to Login
          </Link>
      </div>

      </div>
    
      <ToastContainer
        position="bottom-center"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ResetPassword;
