// CertificateView.js
import React, { useRef, useEffect } from 'react';
import Certificate from './certificate';
import html2canvas from 'html2canvas';

const CertificateView = () => {
  const certRef = useRef(null);

  useEffect(() => {
    // Scroll to the element with id="cert" when the page loads
    if (certRef.current) {
      certRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className='flex justify-center'>
    <div className='w-[58%]'>
      {/* Element with id="cert" */}
      <div ref={certRef} id="cert">
        {/* Your certificate content */}
        <Certificate/>
      </div>
    </div>
    </div>
  );
};

export default CertificateView;
