import { useState } from "react";
import { Link } from "react-router-dom";
import Input from "../../components/input";
import Button from "../../components/button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../utils/apiConfig";

const SignUp = () => {
  const [formData, setFormData] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);

    axiosInstance
      .post(`/users/signup`, formData)
      .then(function (response) {
        notify(response.data.message);
        setFormData({});
        setIsButtonDisabled(false);
      })
      .catch(function (error) {
        notify(error.response.data.message);
        setIsButtonDisabled(false);
      });
  };

  return (
    <div className=" h-screen sm:h-auto bg-[#F5FAFF] py-16 ">
      <div className=" bg-white max-w-xs sm:max-w-lg md:max-w-xl mx-auto rounded-xl pb-10 py-4 px-5 sm:py-5 sm:px-10 md:py-10 md:px-16 overflow-y-auto">
        <div className=" space-x-1 sm:space-y-2">
          <p className=" text-xl md:text-2xl font-semibold">
            Create your account
          </p>
          <p className=" text-base md:text-lg font-poppins">
            Let’s get you started!
          </p>
        </div>

        <form className=" mt-9 space-y-5 flex flex-col" onSubmit={handleSubmit}>
          <div className=" space-y-4">
            <div className=" fullnamee space-y-4">
              <Input
                id="first_name"
                name="first_name"
                label="Firstname"
                type="text"
                value={formData.first_name || ""}
                placeholder="First Name"
                handleChange={handleChange}
              />
              <Input
                id="last_name"
                name="last_name"
                label="Lastname"
                type="text"
                value={formData.last_name || ""}
                placeholder="Last Name"
                handleChange={handleChange}
              />
              <Input
                id="email"
                name="email"
                label="Email Address"
                type="email"
                value={formData.email || ""}
                placeholder="myself@myemail.com"
                handleChange={handleChange}
              />
              <Input
                id="phone_number"
                name="phone_number"
                label="Phone Number"
                type="text"
                value={formData.phone_number || ""}
                placeholder="Phone number"
                handleChange={handleChange}
              />
            </div>

            <div className=" space-y-4">
              <Input
                id="address"
                name="address"
                label="Address"
                type="text"
                value={formData.address || ""}
                placeholder="Address"
                handleChange={handleChange}
              />
              <Input
                id="date of birth"
                name="dob"
                label="Date of Birth"
                type="text"
                placeholder="mm/dd/yyyy"
                value={formData.dob || ""}
                handleChange={handleChange}
              />
            </div>
            <div className=" fullnamee space-y-4 ">
              <Input
                id="years_of_exp"
                name="years_of_exp"
                label="Years of Experience"
                type="text"
                value={formData.years_of_exp || ""}
                handleChange={handleChange}
              />
              <Input
                id="employer"
                name="employer"
                label="Employer's Name"
                type="text"
                value={formData.employer || ""}
                placeholder="CoyName"
                handleChange={handleChange}
              />
            </div>

            <div className="flex flex-col mt-5">
              <label className=" text-base md:text-xl font-normal label ">
                Membership Type <span className="asterix">*</span>
              </label>
              <select
                className="block w-full border-2 md:px-4 md:py-3 mt-2 text-sm md:text-base border-black rounded-md focus:outline-none focus:shadow-outline bg-white"
                value={formData.membership_type || ""}
                name="membership_type"
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="Student">Student</option>
                <option value="Corporate">Corporate</option>
                <option value="Graduate">Graduate</option>
              </select>
            </div>

            <div className=" fullnamee space-y-4">
              <Input
                id="password"
                name="password"
                label="Password"
                type="password"
                value={formData.password || ""}
                handleChange={handleChange}
              />
              <Input
                id="confirm_password"
                name="confirm_password"
                label="Confirm Password"
                type="password"
                value={formData.confirm_password || ""}
                handleChange={handleChange}
              />
            </div>
          </div>

          <Button
            disabled={isButtonDisabled}
            children={isButtonDisabled ? "Loading..." : "Sign Up"}
            classname="mt-32"
          >
            Sign Up
          </Button>

          <div className="flex justify-center">
            <span>Already have an account?</span>{" "}
            <Link className=" text-blue-600" to={"/login"}>
              Login
            </Link>
          </div>
        </form>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default SignUp;
