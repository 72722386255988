const Button = ({ children, classname, disabled, onclick, type}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={`md:text-base py-3 rounded-md ${classname} ${disabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-[#2D4F93] text-white'}`}
      onClick={onclick}
    >
      {" "}
      {children}
    </button>
  );
};

export default Button;
