import SideNavBar from "../../components/side-nav-bar";
import Footer from "../../components/footer";
import { axiosInstance } from "../../utils/apiConfig";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion, AnimatePresence } from "framer-motion";
import "../../styles/discussion.scss";

const Discussion = ({ socket, setupSocket }) => {
  const role = JSON.parse(localStorage.getItem("user-role"));
  let activeSocket = false
  let currentToastId = null;
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [groupId, setGroupId] = useState(null);
  const [userJoinedGroups, setUserJoinedGroups] = useState([]);
  const [userUnjoinedGroups, setUserUnjoinedGroups] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [groupMembers, setGroupMembers] = useState("--");
  const [messages, setMessages] = useState([]);
  const [track, setTrack] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const chatContainerRef = useRef(null);
  const [sendMessageEnabled, setSendMessageEnabled] = useState(false);
  // const [activeUsers, setActiveUsers]= useState([])

  const notify = (text) => {
    if (currentToastId !== null) {
      // dismiss ongoing notification
      toast.dismiss(currentToastId);
    }
    const toastOptions = {
      toastId: uuidv4(), // generate unique ID
      position: toast.POSITION.TOP_RIGHT,
    };

    const toastElement = toast.info(text, toastOptions);
    currentToastId = toastOptions.toastId; // update current ID

    setTimeout(() => {
      toast.dismiss(currentToastId);
      currentToastId = null; // reset current ID
    }, 3000);

    return toastElement;
  };

  // useEffect(() => {
  //   fetchActiveUsers();
  // }, []);

  const CreateNewGroupComponent = () => {
    return (
      <div className="w-screen h-screen  bg-black bg-opacity-70 z-10 fixed top-0 left-0">
        <div className="absolute w-full h-full flex flex-col justify-center items-center">
          <div className="flex flex-col  w-[45vw] bg-white p-10 rounded relative transition-opacity create-group-modal">
            <p
              className="absolute top-3 right-6  text-gray-400  font-semibold cursor-pointer hover:text-[#2D4F93]"
              onClick={() => {
                setShowCreateGroup(false);
              }}
            >
              &#10005;
            </p>
            <h1 className="mb-6 font-semibold text-lg">Create New Group</h1>

            <div>
              <label
                for="group-name"
                className="block text-sm font-medium text-gray-700"
              >
                Group Name
              </label>
              <input
                type="text"
                placeholder="Enter Group Name"
                className="w-full h-[45px] border border-gray-300 rounded p-2 mt-2 text-sm"
              />
            </div>

            <div>
              <button
                disabled={isButtonDisabled}
                className={`md:text-base mt-6 p-3 rounded-md ${
                  isButtonDisabled
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-[#2D4F93] text-white"
                }`}
                onClick={(e) => {
                  createGroup(e);
                }}
              >
                {isButtonDisabled
                  ? "Creating Group, Please wait..."
                  : "Create Group"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const createGroup = async (e) => {
    setIsButtonDisabled(true);
    const groupName =
      e.target.parentElement.previousElementSibling.lastChild.value;

    if (groupName === "") {
      setIsButtonDisabled(false);
      notify("Please enter a group name");
      return;
    }

    try {
      const response = await axiosInstance.post(
        "/discussion",
        {
          name: groupName,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("datakey")),
          },
        }
      );
      if (response.data.message === "Discussion Group created!") {
        notify(response.data.message);
        fetchUserGroups();
        setIsButtonDisabled(false);
        setShowCreateGroup(false);
      } else {
        notify(response.data.message);
      }
    } catch (err) {
      notify("An error occured, please try again");
      setIsButtonDisabled(false);
    }
  };

  const fetchUserGroups = () => {
    axiosInstance
      .get("/discussion/user-groups", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      })
      .then((res) => {
        setUserJoinedGroups(res.data.joined_groups);
        setUserUnjoinedGroups(res.data.unjoined_groups);
      })
      .catch((err) => {});
  };

  const joinGroup = async (id) => {
    try {
      const response = await axiosInstance.get(`/discussion/join/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      });

      if (response.data.message.includes("have joined")) {
        notify(response.data.message);
        fetchUserGroups();
      } else {
        notify(response.data.message);
      }
    } catch (err) {
      notify("An error occured, please try again");
    }
  };

  const displayGroupMessage = (name, id) => {
    setGroupName(name);
    fetchGroupMessages(id);
  };

  const fetchGroupMessages = async (id) => {
    try {
      const groupInfoResponse = await axiosInstance.get(
        `/discussion/get-group/${id}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("datakey")),
          },
        }
      );
      setGroupMembers(groupInfoResponse.data.users.length);

      const response = await axiosInstance.get(
        `/discussion/get-messages/${id}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("datakey")),
          },
        }
      );

      setMessages(response.data);
      setTimeout(() => {
        setGroupId(id);
      }, 200);
    } catch (err) {}
  };
  
  const sendMessage = async (e) => {
    e.preventDefault();
    
    if (socket) {
      const message = e.target.previousElementSibling.value;
      
      try {
        const response = await axiosInstance.post(
          `/discussion/send-message/${groupId}`,
          {
            message: message,
          },
          {
            headers: {
              Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("datakey")),
            },
          }
        );

        if (response.data.message === "Message sent successfully!") {
          notify(response.data.message);
          e.target.previousElementSibling.value = "";
          fetchGroupMessages(groupId);
          setTrack(!track);
          setSendMessageEnabled(false);
        }
      } catch (err) {
        notify(err);
      }
      
      return;
    }

    notify("Could not send message due to inactive Socket connection😔");

  };

  const GroupComponent = ({ name, groupId, state }) => {
    return (
      <div className="flex justify-between">
        <p
          className={`capitalize ${
            state ? "pointer-events-none" : "cursor-pointer"
          }`}
          onClick={() => displayGroupMessage(name, groupId)}
        >
          {name}
        </p>

        {state && (
          <p
            className="capitalize text-[#2D4F93] cursor-pointer"
            onClick={() => joinGroup(groupId)}
          >
            Join
          </p>
        )}
      </div>
    );
  };

  const SingleMessageComponent = ({ sender, message, user_id }) => {
    return (
      <>
        {user_id === userId ? (
          <div className="min-h-[30px] w-fit max-w-[70%] bg-gray-400 flex flex-col space-y-2 rounded-lg p-2 self-end">
            <p className="text-2D4F93">{message}</p>
          </div>
        ) : (
          <div className="min-h-[30px] w-fit max-w-[70%] bg-gray-400 flex flex-col space-y-2 rounded-lg p-2 ">
            <p className="text-[#2D4F93] font-bold text-sm max-w-[100px] truncate text-ellipsis overflow-clip">
              {sender}
            </p>
            <p className="text-2D4F93">{message}</p>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    setupSocket();
    fetchUserGroups();
    setGroupName();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("newMessage", (message) => {
        const newMessages = [...messages, message];
        setMessages(newMessages);
      });
      return () => {
        socket.off("newMessage");
      };
    }
    //eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    chatContainer.scrollTop = chatContainer.scrollHeight;
  }, [messages]);

  useEffect(() => {
    if (socket) {
      // subscribe to the channel that listens for new messages in the current group
      socket.on(`new_message_${groupId}`, (data) => {
        // update the messages state with the new message
        setMessages((prevMessages) => [...prevMessages, data]);
      });
    }

    // cleanup function to unsubscribe from the channel when the component unmounts
    return () => {
      if (socket) {
        socket.off(`new_message_${groupId}`);
      }
    };
  }, [socket, groupId]);

  useEffect(() => {
    if (socket) {
      socket.emit("joinRoom", groupId);
    }

    return () => {
      //Component Unmount
      if (socket) {
        socket.emit("leaveRoom", groupId);
      }
    };
    //eslint-disable-next-line
  }, [socket, groupId]);
  // const fetchActiveUsers =  () => {
  //    axios
  //     .get(
  //       "https://fintechceosforum.herokuapp.com/api/discussion",
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization:
  //             "Bearer " + JSON.parse(localStorage.getItem("datakey")),
  //         },
  //       }

  //     )
  //     .then((res) => {
  //       setGroups(res)
  //     })
  //     .catch((err) => {
  //
  //     });
  // };
  return (
    <>
      <AnimatePresence>
        {showCreateGroup && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CreateNewGroupComponent />
          </motion.div>
        )}
      </AnimatePresence>

      <div className="flex flex-col lg:flex-row lg:space-x-4 text-[#000000] mobile-mt">
        <SideNavBar />

        <div className="flex flex-col space-y-10 px-10 py-20 max-h-screen w-[60%] discussion-container">
          <div className="flex flex-col space-y-3 p-2 border-solid border-2 border-gray-200 min-h-[100%] max-h-full pt-8 discussion-content-container">
            <div className="flex flex-col justify-between border-b-2 border-gray-200 pb-3 pr-3 remove-padding remove-margin">
              <div className="flex justify-between">
                <h1 className="text-3xl font-medium header-text">
                  ANSTESTERS Discussions
                </h1>
                <div className="flex items-center space-x-1 active-members">
                  <span className="min-w-[12px] max-w-[12px] min-h-[12px] max-h-[12px] bg-[#8CFA9E] rounded-lg"></span>
                  <p>{groupMembers} Members</p>
                </div>
              </div>

              <div className="flex items-center space-x-2 pt-2 pl-2 mb-1">
                <h3 className="text-2xl group-topic">Group Topic:</h3>
                {groupName !== "" && (
                  <h1 className="text-xl capitalize">{groupName}</h1>
                )}
              </div>
            </div>

            <div
              className="overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full h-full"
              ref={chatContainerRef}
            >
              {!groupId && (
                <div className="flex items-center justify-center h-[100%] empty-chat">
                  <h1 className="text-2xl text-center ">
                    Select a group to start chatting
                  </h1>
                </div>
              )}
              {groupId && (
                <div className=" p-2 bg-gray-200 rounded-lg min-h-[100%] chat-box">
                  {messages.length > 0 && (
                    <div className="">
                      <div className="flex flex-col p-3 space-y-3">
                        {messages.map((message, index) => (
                          <SingleMessageComponent
                            key={index}
                            sender={message.name}
                            message={message.message}
                            user_id={message.userId}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="self-baseline w-[100%]">
              {groupId && (
                <div className="sticky bottom-2 flex justify-center w-[100%]">
                  <div className="flex justify-between items-center space-x-3 w-full">
                    <input
                      type="text"
                      className="min-w-[80%] rounded-lg "
                      placeholder="Type your message here"
                      onChange={(e) => {
                        e.target.value !== ""
                          ? setSendMessageEnabled(true)
                          : setSendMessageEnabled(false);
                      }}
                    />
                    <button
                      className={` text-white rounded-lg px-4 py-2 w-full ${
                        sendMessageEnabled
                          ? "bg-[#2D4F93]"
                          : "bg-[gray] cursor-not-allowed"
                      }`}
                      disabled={!sendMessageEnabled}
                      onClick={(e) => {
                        !socket && setupSocket()
                        setSendMessageEnabled(false);
                        sendMessage(e);
                      }}
                    >
                      Send
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="w-[20%] pr-16 py-20 group-container">
          <div className="flex flex-col justify-between space-y-5 full border-solid border border-gray-200 h-full py-8 px-5 group-content-container">
            <div className="min-h-[50%]">
              <h1 className="text-3xl font-medium mb-4 header-text">
                My Discussions
              </h1>

              <div className="flex flex-col space-y-2">
                {userJoinedGroups.length === 0 &&
                  userUnjoinedGroups.length === 0 && (
                    <div className="my-20 text-center">
                      <p>No groups available</p>
                      {role === "admin" && (
                        <h1
                          className="font-sm text-[#2D4F93] cursor-pointer"
                          onClick={() => setShowCreateGroup(true)}
                        >
                          Create New Group
                        </h1>
                      )}
                    </div>
                  )}
                {userJoinedGroups.map((group, index) => (
                  <GroupComponent
                    key={index}
                    name={group.name}
                    groupId={group._id}
                    state={false}
                  />
                ))}
                {userUnjoinedGroups.map((group, index) => (
                  <GroupComponent
                    key={index}
                    name={group.name}
                    groupId={group._id}
                    state={true}
                  />
                ))}
              </div>
            </div>

            {role === "admin" && (
              <div>
                <h1
                  className="font-sm text-[#2D4F93] cursor-pointer"
                  onClick={() => setShowCreateGroup(true)}
                >
                  Create New Group
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer
        position="bottom-center"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Discussion;
