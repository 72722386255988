import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import progress from "../assets/svg/progress.svg";
import { axiosInstance } from "../utils/apiConfig";
// import { testData } from "./test-data";

const UserProfile = () => {
  const [users, setUsers] = useState([]);
  const [usersCreatedThisMonth, setUsersCreatedThisMonth] = useState("---");
  const [userPercentage, setUserPercentage] = useState("0");
  const [userBirthday, setUserBirthday] = useState(["---", "--"]);

  const Avatar = ({ user }) => {
    return (
      <div
        className="flex flex-col items-center cursor-pointer"
        onClick={() => setUserBirthday(getBirthMonthAndDate(user))}
      >
        <div className=" bg-primary rounded-full w-[60px] h-[60px]" />
        <p className=" text-sm">
          {user.first_name} {user.last_name}
        </p>
      </div>
    );
  };

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get("/admin/getusers", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      });
      setUsers(response.data.users);
    } catch (err) {}
  };

  const UIUsers = (users) =>
    users.length < 7 ? users : users.splice(3, users.length - 3);

  const getUsersCreatedThisMonth = (users) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const usersCreatedThisMonth = users.filter((user) => {
      const createdAt = new Date(user.createdAt);
      const createdMonth = createdAt.getMonth();
      const createdYear = createdAt.getFullYear();

      return createdMonth === currentMonth && createdYear === currentYear;
    });

    return usersCreatedThisMonth;
  };

  const getBirthMonthAndDate = (user) => {
    const birthDate = new Date(user.dob);
    const month = birthDate.toLocaleString("default", { month: "short" });
    const date = birthDate.getDate().toString().padStart(2, "0");

    return [month, date];
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  useEffect(() => {
    setUsersCreatedThisMonth(getUsersCreatedThisMonth(users).length);
    setUserPercentage(
      Math.round((getUsersCreatedThisMonth(users).length / users.length) * 100)
    );
  }, [users]);

  return (
    <>
      <div className=" rounded-2xl border-[1px] px-3 py-5">
        <div className="flex justify-between mb-4 items-center">
          <p className="text-xl font-bold">Users Profile</p>
        </div>

        <div className=" flex flex-col sm:flex-row w-full space-y-6 sm:space-y-0 sm:space-x-3">
          <div className=" sm:w-[45%] rounded-2xl border-[1px] py-8 px-2">
            <div div className="flex justify-between px-4 mb-4">
              <p className=" text-[#2D4F93] text-lg mb-4 block font-medium">
                Registered Users
              </p>

              <Link to={"/member-profile"} className=" text-md text-[#D9D9D9]">
                See All
              </Link>
            </div>

            <div className="grid grid-cols-3 gap-2 text-center capitalize">
              {UIUsers(users).map((user) => (
                <Avatar user={user} />
              ))}
            </div>
          </div>

          <div className="sm:w-[55%] space-y-3 text-center">
            <div className="w-[100%] space-x-3  flex">
              <div className=" w-[50%] px-4 sm:px-8 py-5 bg-[#EBF0F9] rounded-2xl flex justify-center items-center flex-col">
                <span className="text-sm font-medium">Users Birthday</span>
                <span className="text-sm">{userBirthday[0]}</span>
                <span className="text-2xl font-medium">{userBirthday[1]}</span>
              </div>
              <div className=" w-[50%] px-4 sm:px-8 py-5 bg-[#FAE1D1] rounded-2xl flex justify-center items-center flex-col">
                <span className="text-sm font-medium">Total Users</span>
                <span className="text-2xl font-medium">{users.length}</span>
              </div>
            </div>

            <div className="w-[100%] px-4 sm:px-8 py-5 bg-[#EBF9F4]  flex justify-between rounded-2xl">
              <div className="  flex justify-center items-center flex-col">
                <span className="text-sm font-medium">New Users</span>
                <span className="text-sm">(Monthly)</span>
                <span className="text-2xl font-medium">
                  {usersCreatedThisMonth}
                </span>
              </div>

              <div className="relative">
                <img src={progress} alt="raoatech" />
                <span className="text-xl font-medium absolute center">
                  {userPercentage}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
