import React from "react";
import { useState, useEffect } from "react";
import { axiosInstance } from "../../utils/apiConfig";
import SideNavBar from "../../components/side-nav-bar";
import Table from "../../components/member-table";
import Footer from "../../components/footer";
import { searchFunction } from "../../utils/reusables";
import { testData } from "../../components/test-data";

const MemberProfile = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axiosInstance
      .get("/admin/getusers", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      })
      .then((res) => {
        setUsers(res.data.users);
      })
      .catch((err) => {});
  };

  const searchKeys = [
    "first_name",
    "last_name",
    "email",
    "address",
    "phone_number",
    "dob",
  ];

  return (
    <>
      <div className=" flex flex-col lg:flex-row lg:space-x-4 text-[#000000]">
        <SideNavBar />

        <div className="flex flex-col space-y-10 px-10 py-20 max-w-[1470px] max-h-screen">
          {/* Search Bar */}
          <div className="w-full">
            <form class="flex items-center">
              <div class="relative w-full">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type={"text"}
                  class={
                    "p-4 border-none ring-2 ring-[#2D4F93] focus:outline-none focus:ring-2 focus:ring-gray-300 text-gray-900 text-sm rounded-sm w-full pl-10 placeholder-gray-400"
                  }
                  placeholder="Search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </form>
          </div>

          <div className="flex flex-col space-y-3 p-2 border-solid border-2 border-gray-200">
            <h1 className="text-3xl font-medium border-b border-gray-300 py-2 pl-2">
              Members Profile
            </h1>

            {/* Insert Table */}
            <Table users={searchFunction(users, search, searchKeys)} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MemberProfile;
