// import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className=" bg-primary mt-10 px-5">
      <p className=" text-base md:text-xl font-medium text-center py-8">
        © 2023 All Rights Powered by{" "}
        <a href="https://www.raoatech.com/">RAOATECH</a>
      </p>
    </footer>
  );
};

export default Footer;
