import "../../src/styles/mobile-nav.scss";
import Hamburger from "../assets/svg/hamburger-new.svg";
import Logo from "../assets/images/ANSTesters-black-removebg.png";
import CancelIcon from "../assets/svg/cancel-svgrepo-com.svg";
import homeSvg from "../assets/svg/home.svg";
import profileSvg from "../assets/svg/profile.svg";
import discussionSvg from "../assets/svg/discussion1.svg";
import calendarSvg from "../assets/svg/calendar.svg";
import resourceSvg from "../assets/svg/resource.svg";
import ExportSvg from "../assets/svg/exportSvg.svg";
import billsSvg from "../assets/svg/bills.svg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../utils/apiConfig";
import Papa from "papaparse";
import { Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

const MobileNav = ({setMobileNav}) => {
  const loc = window.location.pathname;
  const [menuActive, setMenuActive] = useState(false);
  const role = JSON.parse(localStorage.getItem("user-role"));
  const [show, setShow] = useState(false);
  const [showBillsSubMenu, setShowBillsSubMenu] = useState(false);
  const [showVotingSubMenu, setShowVotingSubMenu] = useState(false);

  const links = [
    {
      to: role === "user" ? "/user-dashboard" : "/admin-dashboard",
      text: "Home",
      icon: homeSvg,
    },
    { to: "/edit-profile", text: "Edit Profile", icon: profileSvg },
    { to: "/user/discussion", text: "Discussion", icon: discussionSvg },
    {
      to: role === "admin" ? "/admin/event-calendar" : "/user/event-calendar",
      text: "Event Calendar",
      icon: calendarSvg,
    },
    {
      to:
        role === "admin" ? "/admin/resource-library" : "/user/resource-library/recent-uploads",
      text: "Resource Library",
      icon: resourceSvg,
    },
    {
      to: role === "admin" ? "/admin/certificate" : "/user/certificate",
      text: "Certificate  ",
      icon: resourceSvg,
    },
  ];

  const LinkListItem = ({ to, text, icon }) => {
    return (
      <Link
        to={to}
        className={`menu-item ${loc.includes(to) ? "text-[#2D4F93]" : ""}`}
        onClick={() => setMenuActive(false)}
      >
        <img src={icon} alt="raoatech" className="menu-item-icon" />
        <p className="menu-item-text">{text}</p>
      </Link>
    );
  };

  const handleDownloadCSV = async (event) => {
    event.preventDefault();
    try {
      // Make an HTTP GET request to fetch the CSV data from the backend
      const response = await axiosInstance.get(
        "/admin/getusers" /*"/admin/export"*/,
        {
          // responseType: "arraybuffer",

          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("datakey")), //to get the token of who logged in
          },
        }
      );

      const users = response.data;

      //     // Check if the users array is empty or not defined
      // if (!Array.isArray(users) || users.length === 0) {
      //   console.error('No user data found.');
      //   return;
      // }
      const csvData = Papa.unparse(users);
      //const csvData = convertToCSV(users);
      console.log(csvData);
      // Create a Blob with the CSV data received from the backend
      const csvBlob = new Blob([csvData], { type: "text/csv" });
      console.log(csvBlob);

      // Generate a URL for the Blob
      const csvUrl = URL.createObjectURL(csvBlob);

      // Create an anchor element and set its attributes
      const downloadLink = document.createElement("a");
      downloadLink.href = csvUrl;
      downloadLink.download = "users.csv"; // Set the filename for the download

      // Programmatically click the anchor to trigger the download
      downloadLink.click();

      // Cleanup: revoke the URL to free up memory
      URL.revokeObjectURL(csvUrl);

      // saveAs(csvBlob, "users.csv")
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  return (
    <>
      <nav className="mobile-navbar">
        <div className={`mobile-menu ${menuActive ? "open" : "close"}`}>
          <div className="menu-header">
            <img src={Logo} alt="" className="logo" />
            <img
              src={CancelIcon}
              alt=""
              className="cancel-icon"
              onClick={() => setMenuActive(false)}
            />
          </div>

          <div className="menu-body">
            <div className="menu-list">
              {links.map((link, index) => (
                <LinkListItem
                  key={index}
                  to={link.to}
                  text={link.text}
                  icon={link.icon}
                />
              ))}

              {role === "admin" ? (
                <>
                  <div
                    className={`menu-item ${
                      loc.includes("voting") ? " text-[#2D4F93]" : ""
                    }`}
                    onClick={() => setShowVotingSubMenu(!showVotingSubMenu)}
                  >
                    <img
                      src={resourceSvg}
                      alt="raoatech"
                      className="menu-item-icon"
                    />
                    <p className="menu-item-text">Voting</p>
                  </div>
                  {(showVotingSubMenu || loc.includes("voting")) && (
                    <div className="menu-sub-item-list">
                      <Link
                        to={"/admin/voting/create-votes"}
                        className="menu-sub-item"
                        onClick={() => setMenuActive(false)}
                      >
                        <p
                          className={`menu-sub-item-text ${
                            loc.includes(
                              role === "admin"
                                ? "/admin/voting/create-votes"
                                : "/user/voting"
                            )
                              ? "text-[#2D4F93]"
                              : ""
                          }`}
                        >
                          Create Vote
                        </p>
                      </Link>
                      <Link
                        to={"/admin/voting/existingVotes"}
                        className="menu-sub-item"
                        onClick={() => setMenuActive(false)}
                      >
                        <p
                          className={`menu-sub-item-text ${
                            loc.includes(
                              role === "admin"
                                ? "/admin/voting/existingVotes"
                                : "/user/voting"
                            )
                              ? "text-[#2D4F93]"
                              : ""
                          }`}
                        >
                          Existing Vote
                        </p>
                      </Link>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Link
                    to={"/user/voting"}
                    className={`menu-item ${
                      loc.includes("voting") ? " text-[#2D4F93]" : ""
                    }`}
                    onClick={() => setMenuActive(false)}
                  >
                    <img
                      src={resourceSvg}
                      alt="raoatech"
                      className="menu-item-icon"
                    />
                    <p className="menu-item-text">Voting</p>
                  </Link>
                </>
              )}

              <div
                className={`menu-item ${
                  loc.includes("bills") ? " text-[#2D4F93]" : ""
                }`}
                onClick={() => setShowBillsSubMenu(!showBillsSubMenu)}
              >
                <img src={billsSvg} alt="raoatech" className="menu-item-icon" />
                <p className="menu-item-text">Bills</p>
              </div>
              {(showBillsSubMenu || loc.includes("bills")) && (
                <div className="menu-sub-item-list">
                  {role === "admin" && (
                    <Link
                      to={"/admin/bills/create-new-bill"}
                      className="menu-sub-item"
                      onClick={() => setMenuActive(false)}
                    >
                      <p
                        className={`menu-sub-item-text ${
                          loc.includes(
                            role === "admin" && "/admin/bills/create-new-bill"
                          )
                            ? "text-[#2D4F93]"
                            : ""
                        }`}
                      >
                        Create Bill
                      </p>
                    </Link>
                  )}

                  <Link
                    to={
                      role === "admin" ? "/admin/bills/history"
                        : role === "user"
                        ? "/user/bills/pending-bills"
                        : ""
                    }
                    className="menu-sub-item"
                    onClick={() => setMenuActive(false)}
                  >
                    <p
                      className={`menu-sub-item-text ${
                        loc.includes(
                          role === "admin"
                            ? "/admin/bills/history"
                            : "/user/bills/pending-bills"
                        )
                          ? "text-[#2D4F93]"
                          : ""
                      }`}
                    >
                      {role === "admin" ? "Bills History" : "Pending Bills"}
                    </p>
                  </Link>

                  <Link
                    to={
                      role === "admin" ? "/admin/bills/payment-report"
                        : role === "user"
                        ? "/user/bills/payment-report"
                        : ""  
                    }
                    className="menu-sub-item"
                    onClick={() => setMenuActive(false)}
                  >
                    <p
                      className={`menu-sub-item-text ${
                        loc.includes(
                          role === "admin"
                            ? "/admin/bills/payment-report"
                            : "/user/bills/payment-report"
                        )
                          ? "text-[#2D4F93]"
                          : ""
                      }`}
                    >
                      Payment Report
                    </p>
                  </Link>
                </div>
              )}

              {role === "admin" && (
                <div className="menu-item mb-4" onClick={handleDownloadCSV}>
                  <img
                    src={ExportSvg}
                    alt="export-icon"
                    className="menu-item-icon"
                  />
                  <p className="menu-item-text">Export</p>
                </div>
              )}

              <div className="logout-container">
                <p className="logout-text" onClick={() => setShow(!show)}>
                  Logout
                </p>
              </div>
            </div>
          </div>

          <Modal
            show={show}
            size="md"
            popup={true}
            onClose={() => setShow(!show)}
            className="h-[100vh]"
          >
            <Modal.Header />
            <Modal.Body>
              <div className="text-center">
                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                <h3 className="mb-6 text-base dark:text-gray-400 font-medium">
                  Are you sure you want to Logout?
                </h3>
                <div className="flex flex-col gap-4 justify-center">
                  <Link
                    color="bg-[#2D4F93]"
                    onClick={() => setShow(!show)}
                    className="text-black border font-medium bg-white hover:bg-gray-200 p-2 rounded-md"
                  >
                    Cancel
                  </Link>
                  <Link
                    to={"/login"}
                    color="bg-[#2D4F93]"
                    onClick={() => {
                      setMobileNav(true);
                      setMenuActive(false);
                      setShow(!show);
                      localStorage.clear();
                    }}
                    className="text-white bg-[#2D4F93] hover:bg-blue-500 p-2 rounded-md"
                  >
                    Logout
                  </Link>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {/* {--------------------- */}
        <img
          src={Hamburger}
          alt="Menu-Icon"
          className="hamburger-menu"
          onClick={() => setMenuActive(true)}
        />
        <div className="current-page">
          <p className="current-page-text">
            {(loc.includes("dashboard") && "Dashboard") ||
              (loc.includes("bills") && "Bills") ||
              (loc.includes("profile") && "Profile") ||
              (loc.includes("discussion") && "Discussion") ||
              (loc.includes("calendar") && "Events Calendar") ||
              (loc.includes("library") && "Resource Library") ||
              (loc.includes("certificate") && "Certificate") ||
              (loc.includes("voting") && "Voting")}
          </p>
        </div>
      </nav>
    </>
  );
};

export default MobileNav;
