import ANSTestersLogo from "../../../assets/images/ANSTesters-black-removebg.png";
import {
  Page,
  Document,
  Image,
  Text,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import { currencyFormat } from "../../../utils/reusables";

const styles = StyleSheet.create({
  body: {
    paddingTop: 40,
    paddingHorizontal: 45,
  },

  logoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
  },

  logo: {
    width: "25vw",
  },

  logoName: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#1C6DAD",
    textAlign: "center",
  },

  headerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
  },

  address: {
    fontSize: 12,
  },

  phoneNum: {
    fontSize: 12,
  },

  email: {
    fontSize: 12,
    marginBottom: 25,
  },

  headerText: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#1C6DAD",
    marginBottom: 20,
  },

  tableContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
  },

  tableContent: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
  },

  tableHeaderText: {
    fontSize: 12,
    marginTop: 20,
  },

  table: {
    display: "flex",
    flexDirection: "column",
    borderCollapse: "collapse",
    fontSize: 12,
    border: "1px solid #424344",
  },

  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #424344",
    minWidth: "50vw",
  },

  tableRowDiff: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "none",
    minWidth: "50vw",
  },

  tableHeader: {
    flex: 1,
    padding: 10,
    borderRight: "1px solid #424344",
    justifyContent: "right",
    fontWeight: "bold",
  },

  tableHeaderDiff: {
    flex: 1,
    padding: 10,
    borderRight: "none",
    justifyContent: "right",
  },

  tableData: {
    flex: 1,
    padding: 10,
  },

  tableDataDiff: {
    flex: 1,
    padding: 10,
    borderRight: "1px solid #424344",
  },

  successfulText: {
    color: "#1C6DAD",
    padding: 5,
    border: "1px solid #424344",
    borderRadius: 5,
    fontSize: 8,
  },

  footerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 50,
    textAlign: "center",
    width: "100vw",
  },

  footerText: {
    fontSize: 8,
  },

  tableHeadHidden: {
    visibility: "hidden",
  },

  tableDataRight: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    fontWeight: "600",
  },
});

const PaymentReceipt = ({ receiptDetails }) => {
  return (
    <>
      <Document>
        <Page style={styles.body}>
          <View style={styles.logoContainer}>
            <Image style={styles.logo} src={ANSTestersLogo} />
            <Text style={styles.logoName}>
              Association Of Nigeria Software Testers
            </Text>
          </View>

          <View style={styles.headerContainer}>
            <Text style={styles.address}>
              21A, Akanbi Disu St, Lekki Phase 1, Lagos
            </Text>
            <Text style={styles.phoneNum}>Phone Number: +234 706 933 2527</Text>
            <Text style={styles.email}>
              Email: partnership@anstesters.org, anstexecutives2021@gmail.com
            </Text>
            <Text style={styles.headerText}>PAYMENT RECEIPT</Text>
          </View>

          <View style={styles.tableContainer}>
            <View style={styles.tableContent}>
              <Text style={styles.tableHeaderText}>
                Name: {receiptDetails?.name}
              </Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <Text style={styles.tableHeader}>DETAILS</Text>
                  <View style={styles.tableHeaderDiff}>
                    <View style={styles.flexEnd}>
                      <Text style={styles.tableHeadHidden}> &nbsp; </Text>
                    </View>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <Text style={styles.tableDataDiff}>
                    Description
                  </Text>
                  <View style={styles.tableData}>
                    <View style={styles.tableDataRight}>
                      <Text>{receiptDetails?.billname}</Text>
                    </View>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <Text style={styles.tableDataDiff}>
                    Transaction Reference
                  </Text>
                  <View style={styles.tableData}>
                    <View style={styles.tableDataRight}>
                      <Text>{receiptDetails?.transaction_reference}</Text>
                    </View>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <Text style={styles.tableDataDiff}>Payment Method</Text>
                  <View style={styles.tableData}>
                    <View style={styles.tableDataRight}>
                      <Text>{receiptDetails?.modeofpayment}</Text>
                    </View>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <Text style={styles.tableDataDiff}>Amount</Text>
                  <View style={styles.tableData}>
                    <View style={styles.tableDataRight}>
                      <Text>
                        {currencyFormat("NGN", receiptDetails?.amount)}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <Text style={styles.tableDataDiff}>Date Of Transaction</Text>
                  <View style={styles.tableData}>
                    <View style={styles.tableDataRight}>
                      <Text>{receiptDetails?.datecreated.split("T")[0]}</Text>
                    </View>
                  </View>
                </View>

                <View style={styles.tableRowDiff}>
                  <Text style={styles.tableDataDiff}>Payment Status</Text>
                  <View style={styles.tableData}>
                    <View style={styles.tableDataRight}>
                      <Text>SUCCESSFUL</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.footerContainer} fixed>
            <Text style={styles.footerText}>
              DONATED AND POWERED BY RAOATECH
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default PaymentReceipt;
