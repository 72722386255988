import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { axiosInstance } from "../utils/apiConfig";



const EventCalender = () => {
  const role = JSON.parse(localStorage.getItem("user-role"));
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const colors = ["bg-[#FAE1D1]", "bg-[#F0A575]", "bg-[#F7F9FD]"];
  const Card = ({ classname, event }) => {
    return (
      <div
        className={`${classname} px-3 py-6 rounded-lg space-y-3 mb-5 w-[50%] sm:mb-0 capitalize flex flex-col justify-center`}
      >
        <p className="text-lg font-medium">Name of the Event: {event.name}</p>
        <p className="text-lg">Venue: {event.venue} </p>
        <div className="text-lg">
          <p>Date: {formatDateFromObject(event.start_date)}</p>
          <p>Time: {formatTime(event.start_date.split("T")[1])}</p>
        </div>
      </div>
    );
  };

  const filterEventsTodayAndAfter = (events) => {
    if (events.length < 1) return [];
    const currentDate = new Date();

    const filteredEvents = events
      .filter((event) => {
        const startDate = new Date(event.start_date);
        return startDate >= currentDate;
      })
      .sort((a, b) => {
        const dateA = new Date(a.start_date);
        const dateB = new Date(b.start_date);
        return dateA - dateB;
      });

    if (!filteredEvents || filteredEvents.length === 0) {
      return []; // Return an empty array if filteredEvents is null or has no elements
    } else if (filteredEvents.length === 1) {
      return [filteredEvents[0]]; // Return the first element if there is only one
    } else {
      // Return the first two elements if there are more than two
      return [filteredEvents[0], filteredEvents[1]];
    }
  };

  const getUpcomingEvents = async () => {
    const urlSuffix =
      role === "admin" ? "admin/get-events" : "users/user-events";
    try {
      const response = await axiosInstance.get(`/${urlSuffix}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      });
      setUpcomingEvents(filterEventsTodayAndAfter(response.data));
    } catch (err) {}
  };

  const formatDateFromObject = (date) => {
    const currentDate = new Date(); // Get the current date

    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    const month = formattedDate.split(" ")[0];
    const year = formattedDate.split(" ")[2];

    // Get the day value from the start date
    const day = new Date(date).getDate();

    // Add the appropriate suffix to the day
    let daySuffix;
    if (day >= 11 && day <= 13) {
      daySuffix = "th";
    } else {
      switch (day % 10) {
        case 1:
          daySuffix = "st";
          break;
        case 2:
          daySuffix = "nd";
          break;
        case 3:
          daySuffix = "rd";
          break;
        default:
          daySuffix = "th";
          break;
      }
    }

    // Format the final string
    let formattedString = `${day}${daySuffix}, ${month} ${year}`;

    // Add (Today) if the event is the present day
    if (
      currentDate.getDate() === day &&
      currentDate.getMonth() === new Date(date).getMonth() &&
      currentDate.getFullYear() === new Date(date).getFullYear()
    ) {
      formattedString += " (Today)";
    }

    return formattedString;
  };

  const formatTime = (time) => {
    const hour = time.split(":")[0];
    const minute = time.split(":")[1];
    const suffix = hour >= 12 ? "PM" : "AM";

    return `${hour % 12 || 12}:${minute} ${suffix}`;
  };

  useEffect(() => {
    getUpcomingEvents();
  }, []);
  useEffect(() => {
  }, [upcomingEvents]);
  return (
    <div className=" rounded-2xl border-[1px] px-3 py-5">
      <div className="flex justify-between mb-4 items-center">
        <p className="text-xl font-bold header-text">Events Calendar</p>
        <p className="member-id">Membership ID: {localStorage.getItem("membershipId")}</p>

        <Link to="/admin/event-calendar" className=" text-md text-[#D9D9D9]">
          See All
        </Link>
      </div>

      <div>
        <div className=" bg-[#F7F9FD] rounded-3xl px-2 py-3 inline-block pr-8 space-x-2 ">
          <span className="text-lg font-medium px-3 py-2">Upcoming Events</span>
        </div>

        {upcomingEvents.length < 1 && (
          <p className="text-center p-8">No upcoming events</p>
        )}

        {upcomingEvents.length > 0 && (
          <div className=" flex gap-8 mt-8">
            {upcomingEvents.map((event, index) => (
              <Card classname={colors[index]} event={event} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default EventCalender;
