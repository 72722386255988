import SideNavBar from "../../../components/side-nav-bar";
import SearchBar from "../../../components/searchbar";
import Footer from "../../../components/footer";
import { Link } from "react-router-dom";
import { testData } from "../../../components/test-data";
import { useRef, useState, useEffect, ReactDOM } from "react";
import { axiosInstance, baseURL } from "../../../utils/apiConfig";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import uploadSvg from "../../../assets/svg/upload.svg";
import DownloadSvg from "../../../assets/svg/download.svg";
import deleteIconSvg from "../../../assets/svg/delete-icon.svg";
import uploadSuccessfulIconSvg from "../../../assets/svg/upload-success.svg";
import uploadFailedIconSvg from "../../../assets/svg/upload-failed.svg";
import { searchFunction } from "../../../utils/reusables";
import '../../../styles/admin-resource-library.scss'

const ResourcesLibrary = () => {
  const [state, setState] = useState("recent");
  const [search, setSearch] = useState("");
  const [files, setFiles] = useState([]);
  const [recentFiles, setRecentFiles] = useState([]);
  const [fileStatus, setFileStatus] = useState([]);
  // const [accFiles, setAccFiles] = useState([]);
  const [isDropZoneVisible, setIsDropZoneVisible] = useState(true);

  const [isDeleteIconVisible, setIsDeleteIconVisible] = useState(true);
  const [isUploadSuccessfulIconVisible, setIsUploadSuccessfulIconVisible] =
    useState(false);
  const [isUploadFailedIconVisible, setIsUploadFailedIconVisible] =
    useState(false);
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(false);
  const [isUploadCursorNotAllowed, setIsUploadCursorNotAllowed] =
    useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef();

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleSearch = (find) => {
    setSearch(find);
  };

  const getRecentFiles = async () => {
    try {
      const response = await axiosInstance.get("/users/get-files", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      });

      setRecentFiles(response.data.reverse());
    } catch (err) {}
  };

  const handleDragOver = (e) => {
    e.target.classList.add("opacity-50");
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove("opacity-50");
    e.preventDefault();
  };

  const handleDrop = (e) => {
    setFiles((prevFiles) => [...prevFiles, ...e.dataTransfer.files]);
    e.target.classList.remove("opacity-50");
    e.preventDefault();
  };

  const deleteUploadItem = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const FileListTemplate = ({ file, index, deleteUploadItem }) => {
    const status = fileStatus[index];
    let statusIcon = null;

    if (status === "success") {
      statusIcon = <img src={uploadSuccessfulIconSvg} alt="upload-success" />;
    } else if (status === "failure") {
      statusIcon = <img src={uploadFailedIconSvg} alt="upload-failed" />;
    }
    return (
      <li className="flex justify-between items-center first-letter:min-w-[full] min-h-[60px] p-3 border border-[#2D4F93] bg-[#f5f5f5] rounded-md mb-2 file-upload-item">
        <p>{file.name}</p>

        <div className="actions pr-5">
          <ClipLoader
            color="#2D4F93"
            loading={isUploading}
            // cssOverride={override}
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
          />

          {/* <img className={`${isUploadFailedIconVisible ? '' : 'hidden'}`} src={uploadFailedIconSvg} alt="upload-failed" />
          <img className={`${isUploadSuccessfulIconVisible ? '' : 'hidden'}`} src={uploadSuccessfulIconSvg} alt="upload-success" /> */}
          {statusIcon}

          <button
            className={`
                ${isDeleteIconVisible ? "" : "hidden"}
                `}
            onClick={() => deleteUploadItem(index)}
          >
            <img src={deleteIconSvg} alt="delete-icon" />
          </button>
        </div>
      </li>
    );
  };

  const handleUpload = (e) => {
    setFiles((prevFiles) => [...prevFiles, ...e.target.files]);
    e.preventDefault();
  };

  // Upload the files
  const handleUploadSubmit = (e) => {
    // Remove Delete Icon
    setIsDeleteIconVisible(() => false);
    // Display loading Spinner
    setIsUploading(true);
    // Disable Upload Button
    setIsUploadButtonDisabled(() => {
      setIsUploadCursorNotAllowed(() => true);
      return true;
    });

    uploadFiles(files);
    e.preventDefault();
  };

  const uploadFiles = (files) => {
    if (files.length === 0) {
      // All files have been uploaded
      return;
    }

    const file = files[0];

    const formData = new FormData();
    formData.append("upload", file);

    axiosInstance
      .post("/admin/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      })
      .then(function (response) {
        notify(`${file.name}: ${response.data.message}`);
        setIsUploading(() => false);

        if (response.data.message === "File Uploaded Sucessfully") {
          setIsUploadSuccessfulIconVisible(() => true);
        } else {
          setIsUploadFailedIconVisible(() => true);
        }

        setFiles((prevFiles) => prevFiles.slice(1)); // Remove the uploaded file from the array
        getRecentFiles();
        uploadFiles(files.slice(1)); // Call the helper function with the updated array
      })
      .catch(function (error) {
        notify(`${file.name}: ${error.response.data.message}`);
        setIsUploading(() => false);
        setIsUploadFailedIconVisible(() => true);

        setFiles((prevFiles) => prevFiles.slice(1)); // Remove the uploaded file from the array
        uploadFiles(files.slice(1)); // Call the helper function with the updated array
      });
  };

  const downloadFile = async (file) => {
    notify("Downloading File");
    try {
      const response = await axiosInstance.get(`/users/download/${file._id}`, {
        responseType: "blob",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      });
      if (response.status !== 200) {
        toast.dismiss();
        notify("Download Failed", "error");
        return;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file.filename}`);
      link.click();
      window.URL.revokeObjectURL(link);
      toast.dismiss();
      notify("Download Successful", "success");
    } catch (error) {}
  };

  const UploadUITemplate = ({ file }) => {
    return (
      <div className="w-[30%] min-h-[150px] max-h-[150px] bg-[#f5f5f5] rounded-lg mb-3 mr-3 flex flex-col justif relative upload-item">
        <img
          className="h-[115px] w-full"
          src={`${baseURL}/users/preview/${file._id}`}
          alt="upload-icon"
        />
        <div
          className="flex absolute items-center h-[35px] bottom-0 w-full px-2 cursor-pointer hover:bg-gray-200"
          onClick={() => downloadFile(file)}
        >
          <p className="w-[85%] truncate">{file.filename}</p>
          <img
            className="self-end pb-2"
            src={DownloadSvg}
            alt="download-icon"
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    getRecentFiles();
  }, []);

  useEffect(() => {
    if (files.length >= 5) {
      setIsDropZoneVisible(false);
    } else {
      setIsDropZoneVisible(true);
    }
  }, [files.length]);

  return (
    <>
      <div className="w-screen flex flex-col lg:flex-row lg:space-x-4 text-[#000000] mobile-mt">
        <SideNavBar />

        <div className="flex flex-col space-y-10 px-10 py-20 max-h-screen w-4/5 resource-library-container">
          {state === "recent" ? (
            <>
              <SearchBar onSearch={handleSearch} />

              <div className="flex flex-col space-y-3 p-2 border-solid border-2 border-gray-200 min-h-[600px] max-h-full overflow-auto recent-uploads-container">
                <h1 className="text-3xl font-medium border-b border-gray-300 py-2 pl-2 header-text">
                  Recent Uploads
                </h1>

                {/* Recent Uploads container */}
                <div className="w-full flex items-start justify-between max-w-full flex-wrap p-3 uploads-container">
                  {searchFunction(recentFiles, search, ["filename"]).map(
                    (file) => (
                      <UploadUITemplate file={file} />
                    )
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col space-y-3 p-2 border-solid border-2 border-gray-200 min-h-[90%] upload-files-container">
              <h1 className="text-3xl font-medium border-b border-gray-300 py-2 pl-2 header-text">
                Upload Files
              </h1>

              <div className="flex flex-col items-center justify-center w-full h-full space-y-2">
                <div
                  id="drop-zone"
                  className={`min-w-[60%] min-h-[30%] border border-dashed border-[#2D4F93] rounded-xl flex flex-col items-center justify-center space-y-4 py-10
                ${isDropZoneVisible ? "" : "hidden"}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <img src={uploadSvg} alt="" className="w-[40px]"></img>
                  <div className="flex flex-col space-y-1 justify-center items-center">
                    <h1>
                      Drag and Drop file or
                      <input
                        type="file"
                        name="upload"
                        multiple
                        onChange={handleUpload}
                        hidden
                        ref={inputRef}
                      />
                      <button
                        className="text-[#2D4F93] font-medium"
                        onClick={() => inputRef.current.click()}
                      >
                        &nbsp; Browse
                      </button>
                    </h1>
                    <p className="block text-sm text-centre text-gray-400 supported-files">
                      .xls Files are not supported Maximum of 5 files
                    </p>
                  </div>
                </div>

                <div className="min-w-[60%] max-w-[60%] pt-5 flex flex-col space-y-2 file-upload-container">
                  {files.length > 0 && (
                    <>
                      <ul id="upload-items-list" className="list-none">
                        {files.map((file, index) => (
                          <FileListTemplate
                            key={index}
                            index={index}
                            file={file}
                            deleteUploadItem={deleteUploadItem}
                          />
                        ))}
                      </ul>
                      <div className="w-full flex justify-end items-center pr-5 upload-button-container">
                        <button
                          className={`text-white border-none bg-[#2D4F93] px-10 py-3 rounded-lg ${
                            isUploadCursorNotAllowed ? "cursor-not-allowed" : ""
                          }`}
                          onClick={handleUploadSubmit}
                          disabled={isUploadButtonDisabled}
                        >
                          Upload
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="w-1/5 px-10 py-20 change-state-container">
          <div className="flex flex-col space-y-5 full border-solid border border-[#1e1d1d] h-min py-16 px-5 ">
            <p
              className="block text-lg font-medium cursor-pointer"
              onClick={() => setState("upload")}
            >
              Upload Files
            </p>
            <p
              className="block text-lg font-medium cursor-pointer"
              onClick={() => setState("recent")}
            >
              Recent Uploads
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResourcesLibrary;
