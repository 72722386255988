import SideNavBar from "../../components/side-nav-bar";
import Footer from "../../components/footer";
import Nominee from "../../components/Nominee";
import Button from "../../components/button";
import { useState } from "react";
import Input from "../../components/input";
import { axiosInstance } from "../../utils/apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminVoting = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [page, setPage] = useState(0);
  // const [open, setOpen] = useState(false);
  const [imageCount, setImageCount] = useState(2); // the image component

  // Get the local time zone offset in minutes and convert it to milliseconds
  const x = new Date().getTimezoneOffset() * 60000;
  // Get the current time in UTC and subtract the local time zone offset (Z) and remove the milliseconds
  const currentDateAndTime = new Date(Date.now() - x)
    .toISOString()
    .slice(0, -8);

  const [formData, setFormData] = useState({
    categoryName: "",
    title: "",
    startDate: "",
    endDate: "",
    items: [
      {
        name: "",
        description: "",
        image: null,
      },
    ],
  });

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Handle nested object properties (items) separately
    if (name.startsWith("items")) {
      const [items, index, property] = name.match(/\[(\d+)\]\[(\w+)\]/);
      const updatedItems = [...formData.items];
      updatedItems[index][property] = value;
      setFormData({
        ...formData,
        items: updatedItems,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleAddImage = () => {
    setImageCount((prevCount) => prevCount + 1);
  };
  const handleRemoveImage = () => {
    setImageCount((prevCount) => prevCount - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    setPage(1);

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("categoryName", formData.categoryName);
    formDataToSend.append("startDate", formData.startDate);
    formDataToSend.append("endDate", formData.endDate);

    formData.items.forEach((item, index) => {
      formDataToSend.append(`items[${index}][name]`, item.name);
      formDataToSend.append(`items[${index}][description]`, item.description);
      if (item.image) {
        formDataToSend.append(`items[${index}][image]`, item.image);
      }
    });

    axiosInstance
      .post("/vote/set-poll", formDataToSend, {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDM0ODllMzI1YzI1YjMxMzI5MzE4MjMiLCJyb2xlIjoiYWRtaW4iLCJ1c2VybmFtZSI6ImFobWVkIGRhbm55IiwiaWF0IjoxNjg5OTQ3MTg2fQ.cLTkdjrwr6Cb0WbAsMioPAONsgu37htd2YyH7cr4qGA",
        },
      })
      .then(function (response) {
        notify(response.data.message);
        setIsButtonDisabled(false);
        setFormData({
          ...formData,
          categoryName: "",
          items: [
            {
              name: "",
              description: "",
              image: null,
            },
          ],
        });
      })
      .catch(function (error) {
        notify(error.response.data.message);
        setIsButtonDisabled(false);
      });
  };

  console.log(formData);

  return (
    <>
      <div className="w-screen flex flex-col lg:flex-row lg:space-x-4 text-[#000000]">
        <SideNavBar />

        <section className="flex justify-between px-10 py-20 max-h-screen w-4/5">
          <div className="flex flex-col justify-center border-solid border-2 border-gray-200 w-[99%] px-28 overflow-y-auto scrollbar">
            {page === 0 ? (
              <>
                <h2 className="text-4xl pt-6 mb-6">Voting Details</h2>
                <form action="">
                  <div>
                    <Input
                      id="title"
                      label="Voting Title"
                      type="text"
                      placeholder="Title of the vote"
                      name="title"
                      value={formData.title || ""}
                      handleChange={handleChange}
                    />
                  </div>

                  <p className="text-xl mt-10 font-medium">Voting Period</p>
                  <div className="flex items-center justify-between min-[320px] min-[320px]:space-x-0 mt-5">
                    <Input
                      id="start_date"
                      label={"Start Date"}
                      type="datetime-local"
                      name="startDate"
                      value={formData.startDate || ""}
                      min={currentDateAndTime}
                      handleChange={handleChange}
                      className=" sm:w-[45%] lg:"
                    />

                    <Input
                      id="end_date"
                      name="endDate"
                      label="End Date"
                      type="datetime-local"
                      value={formData.endDate || ""}
                      min={formData.startDate || ""}
                      handleChange={handleChange}
                      className=" sm:w-[45%] min-[320px]:w-full"
                    />
                  </div>
                </form>
                <div className="text-center pb-10">
                  <Button
                    classname={
                      formData.title === "" ||
                      formData.startDate === "" ||
                      formData.endDate === ""
                        ? "mt-16 w-2/3 bg-gray-200 cursor-not-allowed"
                        : "mt-16 w-2/3 hover:opacity-95"
                    }
                    disabled={
                      formData.title === "" ||
                      formData.startDate === "" ||
                      formData.endDate === ""
                    }
                    children={isButtonDisabled ? "Loading..." : "Next"}
                    onclick={() => setPage(page + 1)}
                  ></Button>
                </div>
              </>
            ) : (
              <>
                <h2 className="text-4xl pt-6 mb-6">Voting Category</h2>
                <form className="mt-5 ">
                  <div>
                    <Input
                      id="category"
                      label="Category"
                      type="text"
                      placeholder="Tester of the Year"
                      name="categoryName"
                      value={formData.categoryName || ""}
                      handleChange={handleChange}
                    />
                  </div>
                  <main>
                    <h3 className="text-center mt-10 text-xl">Nominees</h3>
                    <div className="mt-[28px] flex flex-wrap justify-around items-center">
                      {Array.from(Array(imageCount), (_, index) => (
                        <Nominee
                          key={index}
                          name={formData.items[index]?.name || ""}
                          description={formData.items[index]?.description || ""}
                          image={formData.items[index]?.image || null}
                          onChange={(name, description, image) => {
                            const updatedItems = [...formData.items];
                            updatedItems[index] = { name, description, image };
                            setFormData({
                              ...formData,
                              items: updatedItems,
                            });
                          }}
                        />
                      ))}
                    </div>
                    <div className="text-right mb-5">
                      <button
                        type="button"
                        className="text-[#2D4F93] hover:bg-[#2D4F93] hover:text-white hover:px-3 hover:py-2 rounded transition-all"
                        onClick={handleAddImage}
                      >
                        add
                      </button>
                      <button
                        type="button"
                        className="text-[#2D4F93] hover:bg-[#2D4F93] hover:text-white hover:px-3 hover:py-2 rounded transition-all ml-3"
                        onClick={handleRemoveImage}
                      >
                        remove
                      </button>
                    </div>
                  </main>
                  <div className="text-center">
                    <Button
                      type="submit"
                      classname={"mt-16 w-2/3 hover:opacity-95 "}
                      disabled={isButtonDisabled}
                      children={isButtonDisabled ? "Loading..." : "Submit"}
                      onclick={handleSubmit}
                    ></Button>
                  </div>
                </form>
              </>
            )}
          </div>
        </section>
      </div>
      <Footer />

      <ToastContainer
        position="bottom-center"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AdminVoting;
