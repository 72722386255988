import { Link } from "react-router-dom";
import { useEffect } from "react";


const PageNotFound = ({setMobileNav}) => {
  const role = JSON.parse(localStorage.getItem("user-role"));

  useEffect(() => { 
    setMobileNav(true)
    setTimeout(() => {  
      // redirect to dashboard after 1 seconds
      role === 'admin' ? window.location.href = '/admin-dashboard' : window.location.href = '/user-dashboard'
    }, 1000)
  }, []);
  return (
    <div className="w-screen h-screen flex flex-col gap-3 justify-center items-center">
      <p>Page Not Found</p>
      <Link to={role === 'admin' ? '/admin-dashboard' : '/user-dashboard'} onClick={() => setMobileNav(false)}>Go to Home</Link>
    </div>
  );
};

export default PageNotFound;
