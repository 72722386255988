import React from "react";
import { useState, useEffect } from "react";
import { axiosInstance, paystackKey } from "../../../utils/apiConfig";
import { currencyFormat } from "../../../utils/reusables";
import SearchBar from "../../../components/searchbar";
import SideNavBar from "../../../components/side-nav-bar";
import Footer from "../../../components/footer";
import { searchFunction } from "../../../utils/reusables";
import { PaystackButton } from "react-paystack";
import { toast } from "react-toastify";
import '../../../styles/user-bills.scss'

function UserBills() {
  const [search, setSearch] = useState("");
  const [bills, setBills] = useState([]);
  const [userData, setUserData] = useState({});

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const billPaymentFn = async (id) => {
    notify("Redirecting, Please wait");
    const response = await axiosInstance.post(
      `/users/pay/${id}`,
      {},
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      }
    );

    // redirect
    window.location.href = response.data.url;
  };

  const SingleBillComp = ({ id, name, amount, status }) => {
    return (
      <div className="flex my-4 tableItem">
        <div className="w-[50%]">
          <p className="text-lg max-w-[90%] truncate whitespace-nowrap capitalize">
            {name}
          </p>
        </div>
        <div className="w-[35%]">
          <p className="text-lg">{currencyFormat("NGN", amount)}</p>
        </div>
        <div className="w-[10%]">
          <p className="text-lg capitalize">{status}</p>
        </div>
        <div className="w-[5%] text-right">
          <button
            className="text-[#2D4F93] text-[12px] border rounded-2xl p-1"
            onClick={() => billPaymentFn(id)}
          >
            Pay
          </button>
          {/* <PaystackButton 
            className="text-[#2D4F93] text-[12px] border rounded-2xl p-1" 
            text="Pay"
            publicKey={paystackKey}
            email={userData?.email}
            metadata ={{
              name: `${userData?.name}`,
              bill_name: `${name}`,
              bill_id: `${id}`,
            }}
            reference={id}
            amount={amount*100}
            onClose={() => {notify("Payment Cancelled")}}
            onSuccess={() => {
              // update bills state
              // setBills((prev) => prev.filter((bill) => bill._id !== id));
              getAllBills()
            }}
          /> */}
        </div>
      </div>
    );
  };

  const getAllBills = async () => {
    try {
      const response = await axiosInstance.get("/users/user-bills", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      });
      setUserData({
        name: response.data.first_name + response.data.last_name,
        email: response.data.email,
        id: response.data._id,
      });

      const billsArray = response.data.bills.filter((bill) => {
        return bill.status === "unpaid";
      });

      setBills(billsArray.reverse());
    } catch (err) {}
  };

  const searchKeys = ["bill_name", "bill_amount", "status"];

  const handleSearch = (find) => {
    setSearch(find);
  };

  useEffect(() => {
    getAllBills();
  }, []);

  return (
    <>
      <div className="flex flex-col lg:flex-row lg:space-x-4 text-[#000000] mobile-mt">
        <SideNavBar />

        <div className="flex flex-col space-y-10 px-10 py-20 w-screen md:w-4/5 user-bills-container">
          {/* Search Bar */}
          <SearchBar onSearch={handleSearch} />

          <div className="w-full border-solid border-2 border-gray-200 h-full p-6 flex flex-col user-bills-content">
            <h1 className="font-semibold text-4xl header-text">List Of All Bills</h1>
            <h2 className="text-xl mt-2 sub-header-text">Display a list of all bills</h2>

            {/* Table container */}
            <div className="flex flex-col space-y-4 mt-10 pb-2 mb-3 px-2 border-b-2 border-grey-200  table-head-container">
              {/* Table head */}
              <div className="flex table-head">
                <div className="w-[50%]">
                  <h1 className="font-semibold text-lg">Name</h1>
                </div>
                <div className="w-[35%]">
                  <h1 className="font-semibold text-lg">Amount</h1>
                </div>
                <div className="w-[10%]">
                  <h1 className="font-semibold text-lg">Status</h1>
                </div>
                <div className="w-[5%] min-w-[5%]"></div>
              </div>
            </div>

            {/* Table body */}
            <div className="table-body-container flex flex-col px-2 max-h-[60vh] overflow-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full ">
              {searchFunction(bills, search, searchKeys).map((bill) => (
                <SingleBillComp
                  key={bill._id}
                  id={bill._id}
                  name={bill.bill_name}
                  amount={bill.bill_amount}
                  status={bill.status}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UserBills;
