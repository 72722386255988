import React, { useState, useEffect, useRef } from "react";
import SearchBar from "../../../components/searchbar";
import { currencyFormat, searchFunction } from "../../../utils/reusables";
import { axiosInstance } from "../../../utils/apiConfig";
import Footer from "../../../components/footer";
import SideNavBar from "../../../components/side-nav-bar";
import downloadSvg from "../../../assets/images/download.png";
import { toast } from "react-toastify";
import PaymentReceipt from "./PaymentReceipt";
import { PDFDownloadLink } from "@react-pdf/renderer";
import '../../../styles/user-bills.scss'

const UserPaymentReport = () => {
  const [search, setSearch] = useState("");
  const [paymentReportData, setPaymentReportData] = useState([]);
  const [userData, setUserData] = useState("");
  const [receiptData, setReceiptData] = useState({});
  const receiptRef = useRef(null);

  const handleSearch = (find) => {
    setSearch(find);
  };

  const searchKeys = [
    "bill_name",
    "bill_amount",
    "transaction_ref",
    "mode_of_payment",
    "createdAt",
  ];

  const getPaymentReport = async () => {
    const response = await axiosInstance.get("/users/user-paid-bills", {
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("datakey")),
      },
    });

    const samp = response.data[0];
    setUserData(samp.individual?.first_name + " " + samp.individual?.last_name);
    setPaymentReportData(response.data);
  };

  const Table = ({ data }) => {
    return (
      <table className="block max-h-[700px] overflow-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full capitalize">
        <thead className="block sticky top-0">
          <tr className="flex space-x-5 mb-4">
            <th className="min-w-[64px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-2xl">
              S/N
            </th>

            <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Bill Item
            </th>

            <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Amount
            </th>

            <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Payment Status
            </th>

            <th className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Mode Of Payment
            </th>

            <th className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Transaction Reference
            </th>

            <th className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl">
              Date Created
            </th>

            <th className="min-w-[70px] max-w-[70px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-xl"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <TableItem
              key={index}
              index={index}
              billname={item?.bill_name}
              amount={item?.bill_amount}
              payment_status={item?.status}
              modeofpayment={item?.mode_of_payment}
              transaction_reference={item?.transaction_ref}
              datecreated={item?.createdAt}
            />
          ))}
        </tbody>
      </table>
    );
  };

  const TableItem = ({
    index,
    billname,
    amount,
    payment_status,
    modeofpayment,
    transaction_reference,
    datecreated,
  }) => {
    return (
      <tr className="flex space-x-5 mb-4">
        <td className="min-w-[64px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l font-bold">
          {index + 1}
        </td>

        <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l text-center">
          {billname}
        </td>

        <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
          {currencyFormat("NGN", amount)}
        </td>

        <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
          {payment_status}
        </td>

        <td className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
          {modeofpayment || "N/A"}
        </td>

        <td className="min-w-[300px] max-w-[300px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
          {transaction_reference}
        </td>

        <td className="min-w-[250px] max-w-[250px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l">
          {datecreated?.split("T")[0]}
        </td>

        <td
          className="min-w-[70px] max-w-[70px] h-[60px] border-none bg-[#f5f5f5] flex items-center justify-center text-l cursor-pointer"
          // onClick={() => (

          // )}
        >
          <PDFDownloadLink
            document={
              <PaymentReceipt
                receiptDetails={{
                  name: userData,
                  billname,
                  amount,
                  payment_status,
                  modeofpayment,
                  transaction_reference,
                  datecreated,
                }}
              />
            }
            fileName="payment-receipt.pdf"
          >
            <img src={downloadSvg} alt="" className="w-[40px]" />
          </PDFDownloadLink>
        </td>
      </tr>
    );
  };

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    getPaymentReport();
  }, []);

  useEffect(() => {}, [receiptData]);

  return (
    <>
      <div className="hidden">
        <PaymentReceipt ref={receiptRef} />
      </div>

      <div className="max-w-screen flex flex-col lg:flex-row lg:space-x-4 text-[#000000] mobile-mt">
        <SideNavBar />

        <div className="flex flex-col space-y-10 px-10 py-20 w-screen md:w-4/5 user-payment-report-container">
          {/* Search Bar */}
          <SearchBar onSearch={handleSearch} />

          <div className="flex flex-col space-y-3 p-2 border-solid border-2 border-gray-200">
            <h1 className="text-3xl font-medium border-b border-gray-300 py-2 pl-2">
              Payment Report
            </h1>

            {/* Insert Table */}
            <Table
              data={searchFunction(paymentReportData, search, searchKeys)}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserPaymentReport;
