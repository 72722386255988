import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { axiosInstance } from "../utils/apiConfig";

const AdminDashboardResourceLibrary = () => {
  const [recentFiles, setRecentFiles] = useState([]);
  const Card = ({ resourceName, uploadDate }) => {
    return (
      <div className="flex space-x-5 items-center py-3 pb-5 px-4 border-b-[1px]">
        <div className=" bg-primary min-h-[120px] min-w-[200px]" />
        <div className="space-y-3 ">
          <p className=" text-base font-medium text-[#000000] capitalize">
            {resourceName}
          </p>
          <p className=" text-xs text-[##EBF0F9]">Uploaded at: {uploadDate}</p>
          <Link
            to="/admin/resource-library"
            className=" mt-2 bg-primary py-2 px-4 rounded-3xl inline-block text-center text-base font-medium"
          >
            Learn More
          </Link>
        </div>
      </div>
    );
  };

  const getRecentFiles = async () => {
    try {
      const response = await axiosInstance.get(`/users/get-files`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      });

      setRecentFiles(response.data.reverse());
    } catch (err) {}
  };

  const uiRecentFiles = (files) =>
    files.length <= 2 ? files : [files[0], files[1]];

  useEffect(() => {
    getRecentFiles();
  }, []);

  return (
    <div className="rounded-2xl border-[1px]   py-5">
      <div className="flex justify-between px-4 mb-4 items-center">
        <p className="text-xl font-bold header-text">Resource Library</p>

        <Link to="/admin/resource-library" className=" text-md text-[#D9D9D9]">
          See All
        </Link>
      </div>

      <div className=" space-y-3">
        {recentFiles.length < 1 && (
          <p className="text-center p-8">No Recent Files</p>
        )}
        {uiRecentFiles(recentFiles).map((resource) => (
          <Card
            resourceName={resource.filename}
            uploadDate={resource.uploadDate.split("T")[0]}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminDashboardResourceLibrary;
