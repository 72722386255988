import { useEffect, useState } from "react";
import homeSvg from "../assets/svg/home.svg";
import profileSvg from "../assets/svg/profile.svg";
import Logo from "../assets/images/ANSTesters-black-removebg.png";
import { Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import discussionSvg from "../assets/svg/discussion1.svg";
import calendarSvg from "../assets/svg/calendar.svg";
import resourceSvg from "../assets/svg/resource.svg";
import ExportSvg from "../assets/svg/exportSvg.svg";
import billsSvg from "../assets/svg/bills.svg";
import votingSvg from "../assets/svg/voteinfo-svgrepo-com.svg";
import certificateSvg from "../assets/svg/certificate-badge-svgrepo-com.svg";
import { authentification } from "../utils/reusables";
import React from "react";
import { axiosInstance } from "../utils/apiConfig";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import "../../src/styles/mobile-nav.scss";
import Hamburger from "../../src/assets/svg/hamburger-new.svg";

const SideNavBar = () => {
  authentification();
  const role = JSON.parse(localStorage.getItem("user-role"));

  // const convertToCSV = (data) => {
  //   const csvHeader = Object.keys(data[0]).join(',') + '\n';
  //   const csvRows = data.map((user) => Object.values(user).join(',')).join('\n');
  //   return csvHeader + csvRows;
  // };

  const handleDownloadCSV = async (event) => {
    event.preventDefault();
    try {
      // Make an HTTP GET request to fetch the CSV data from the backend
      const response = await axiosInstance.get(
        "/admin/getusers" /*"/admin/export"*/,
        {
          // responseType: "arraybuffer",

          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("datakey")), //to get the token of who logged in
          },
        }
      );

      const users = response.data;

      //     // Check if the users array is empty or not defined
      // if (!Array.isArray(users) || users.length === 0) {
      //   console.error('No user data found.');
      //   return;
      // }
      const csvData = Papa.unparse(users);
      //const csvData = convertToCSV(users);
      console.log(csvData);
      // Create a Blob with the CSV data received from the backend
      const csvBlob = new Blob([csvData], { type: "text/csv" });
      console.log(csvBlob);

      // Generate a URL for the Blob
      const csvUrl = URL.createObjectURL(csvBlob);

      // Create an anchor element and set its attributes
      const downloadLink = document.createElement("a");
      downloadLink.href = csvUrl;
      downloadLink.download = "users.csv"; // Set the filename for the download

      // Programmatically click the anchor to trigger the download
      downloadLink.click();

      // Cleanup: revoke the URL to free up memory
      URL.revokeObjectURL(csvUrl);

      // saveAs(csvBlob, "users.csv")
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const [click, handleClick] = useState(false);
  const [show, setShow] = useState(false);
  const loc = window.location.pathname;

  const links = [
    {
      to: role === "user" ? "/user-dashboard" : "/admin-dashboard",
      text: "Home",
      icon: homeSvg,
    },
    { to: "/edit-profile", text: "Edit Profile", icon: profileSvg },
    { to: "/user/discussion", text: "Discussion", icon: discussionSvg },
    {
      to: role === "admin" ? "/admin/event-calendar" : "/user/event-calendar",
      text: "Event Calendar",
      icon: calendarSvg,
    },
    {
      to:
        role === "admin" ? "/admin/resource-library" : "/user/resource-library/downloads",
      text: "Resource Library",
      icon: resourceSvg,
    },
    {
      to: role === "admin" ? "/admin/certificate" : "/user/certificate",
      text: "Certificate  ",
      icon: resourceSvg,
    },
  ];

  const LinkListItem = ({ to, text, icon }) => {
    return (
      <Link
        to={to}
        className={`flex sm:px-5 py-2 lg:px-10 lg:py-3 items-center text-lg hover:bg-[#F7F9FD] cursor-pointer hover:border-l-[5px] hover:border-[#2D4F93] md:text-base font-medium ${
          loc.includes(to) ? "border-l-[5px] border-[#2D4F93] bg-[#F7F9FD]" : ""
        }`}
      >
        <img src={icon} alt="raoatech" className="w-6" />
        <p className="ml-2">{text}</p>
      </Link>
    );
  };

  return (
    <>
      <div className="hidden lg:flex h-screen sticky z-1 top-0  py-10 flex-col justify-between border-r-[1px] w-[20%]">
        <span className="px-12 text-center text-base font-bold">
          <img src={Logo} alt="Fintech Ceo's Forum" />
        </span>

        <ul className="h-[60%] flex flex-col justify-between">
          {links.map((link) => (
            <LinkListItem
              key={link.text}
              to={link.to}
              text={link.text}
              icon={link.icon}
            />
          ))}

          <li>
            <Link
              to={
                role === "admin" ? "/admin/voting/create-votes" : "/user/voting"
              }
              className={`flex sm:px-5 py-2 lg:px-10 lg:py-3 items-center text-lg hover:bg-[#F7F9FD] cursor-pointer hover:border-l-[5px] hover:border-[#2D4F93] md:text-base font-medium ${
                loc.includes(
                  role === "admin" ? "/admin/voting" : "/user/voting"
                )
                  ? "border-l-[5px] border-[#2D4F93] bg-[#F7F9FD]"
                  : ""
              }`}
            >
              <img src={resourceSvg} alt="raoatech" className="w-6" />
              <p className="ml-2">Voting</p>
            </Link>
            {loc.includes("/admin/voting") && (
              <ul className="sm:px-8 lg:px-16 items-center text-lg md:text-base font-sm ">
                <Link to={"/admin/voting/create-votes"}>
                  <li
                    className={`px-4 py-2 hover:bg-[#F7F9FD] cursor-pointer  hover:border-[#2D4F93] ${
                      loc.includes(
                        role === "admin"
                          ? "/admin/voting/create-votes"
                          : "/user/voting"
                      )
                        ? "border-l-[3px] border-[#2D4F93]"
                        : ""
                    }`}
                  >
                    Create Vote
                  </li>
                </Link>
                <Link to={"/admin/voting/existingVotes"}>
                  <li
                    className={`px-4 py-2 hover:bg-[#F7F9FD] cursor-pointer  hover:border-[#2D4F93] ${
                      loc.includes(
                        role === "admin"
                          ? "/admin/voting/existingVotes"
                          : "/user/voting"
                      )
                        ? "border-l-[3px] border-[#2D4F93]"
                        : ""
                    }`}
                  >
                    Existing Vote
                  </li>
                </Link>
              </ul>
            )}
          </li>

          <li>
            <Link
              to={
                role === "admin"
                  ? "/admin/bills/history"
                  : "/user/bills/pending-bills"
              }
              className={`flex sm:px-5 py-2 lg:px-10 lg:py-3 items-center text-lg hover:bg-[#F7F9FD] cursor-pointer hover:border-l-[5px] hover:border-[#2D4F93] md:text-base font-medium ${
                loc.includes(role === "admin" ? "/admin/bills" : "/user/bills")
                  ? "border-l-[5px] border-[#2D4F93] bg-[#F7F9FD]"
                  : ""
              }`}
            >
              <img src={billsSvg} alt="raoatech" className="w-6" />
              <p className="ml-2">Bills</p>
            </Link>
            {loc.includes("admin/bills") && (
              <ul className="sm:px-8 lg:px-16 items-center text-lg md:text-base font-sm ">
                <Link to={"/admin/bills/create-new-bill"}>
                  <li
                    className={`px-4 py-2 hover:bg-[#F7F9FD] cursor-pointer  hover:border-[#2D4F93] ${
                      loc.includes(
                        role === "admin"
                          ? "/admin/bills/create-new-bill"
                          : "/user/bills"
                      )
                        ? "border-l-[3px] border-[#2D4F93]"
                        : ""
                    }`}
                  >
                    New Bill
                  </li>
                </Link>
                <Link to={"/admin/bills/history"}>
                  <li
                    className={`px-4 py-2 hover:bg-[#F7F9FD] cursor-pointer  hover:border-[#2D4F93] ${
                      loc.includes(
                        role === "admin"
                          ? "/admin/bills/history"
                          : "/user/bills"
                      )
                        ? "border-l-[3px] border-[#2D4F93]"
                        : ""
                    }`}
                  >
                    Bill History
                  </li>
                </Link>
                <Link to={"/admin/bills/payment-report"}>
                  <li
                    className={`px-4 py-2 hover:bg-[#F7F9FD] cursor-pointer  hover:border-[#2D4F93] ${
                      loc.includes(
                        role === "admin"
                          ? "/admin/bills/payment-report"
                          : "/user/bills"
                      )
                        ? "border-l-[3px] border-[#2D4F93]"
                        : ""
                    }`}
                  >
                    Payment Report
                  </li>
                </Link>
              </ul>
            )}
            {loc.includes("user/bills") && (
              <ul className="sm:px-8 lg:px-16 items-center text-lg md:text-base font-sm ">
                <Link to={"/user/bills/pending-bills"}>
                  <li
                    className={`px-4 py-2 hover:bg-[#F7F9FD] cursor-pointer  hover:border-[#2D4F93] ${
                      loc.includes(
                        role === "user" && "/user/bills/pending-bills"
                      )
                        ? "border-l-[3px] border-[#2D4F93]"
                        : ""
                    }`}
                  >
                    Pending Bills
                  </li>
                </Link>
                <Link to={"/user/bills/payment-report"}>
                  <li
                    className={`px-4 py-2 hover:bg-[#F7F9FD] cursor-pointer  hover:border-[#2D4F93] ${
                      loc.includes(
                        role === "user" && "/user/bills/payment-report"
                      )
                        ? "border-l-[3px] border-[#2D4F93]"
                        : ""
                    }`}
                  >
                    Payment Report
                  </li>
                </Link>
              </ul>
            )}
          </li>

          {role === "admin" && (
            <li className=" flex sm:px-5 py-2 lg:px-10 lg:py-3 items-center text-lg hover:bg-[#F7F9FD] cursor-pointer hover:border-l-[5px] hover:border-[#2D4F93] ">
              <a
                href="#"
                onClick={handleDownloadCSV}
                // download
                className=" flex items-center text-sm md:text-base font-medium"
              >
                <img src={ExportSvg} alt="raoatech" className="w-6" />
                <p className="ml-2">Export</p>
              </a>
            </li>
          )}
        </ul>

        <div className=" text-center text-lg font-medium">
          <button
            onClick={() => setShow(!show)}
            className="text-[#FF0000] rounded-none bg-white mt-5"
          >
            Logout
          </button>
        </div>
      </div>

      <>
        <Modal
          show={show}
          size="md"
          popup={true}
          onClose={() => setShow(!show)}
        >
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
              <h3 className="mb-6 text-base dark:text-gray-400 font-medium">
                Are you sure you want to Logout?
              </h3>
              <div className="flex flex-col gap-4 justify-center">
                <Link
                  color="bg-[#2D4F93]"
                  onClick={() => setShow(!show)}
                  className="text-black border font-medium bg-white hover:bg-gray-200 p-2 rounded-md"
                >
                  Cancel
                </Link>
                <Link
                  to={"/login"}
                  color="bg-[#2D4F93]"
                  onClick={() => {
                    setShow(!show);
                    localStorage.clear();
                  }}
                  className="text-white bg-[#2D4F93] hover:bg-blue-500 p-2 rounded-md"
                >
                  Logout
                </Link>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>

      {/* <nav className=" block lg:hidden px-5 pt-5 ">
        <div className="flex flex-wrap items-center justify-between mx-auto">
          <img src={Logo} className="h-10" alt="Fintech Ceo's Forum" />

          <button
            onClick={() => handleClick(!click)}
            data-collapse-toggle="navbar-hamburger"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
            aria-controls="navbar-hamburger"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        {click && (
          <div className="" id="navbar-hamburger">
            <ul className="flex flex-col mt-4 rounded-lg ">
              {links.map((link) => (
                <Link to name={link.name} />
              ))}
              <li className=" flex sm:px-5 py-2 lg:px-10 lg:py-3 items-center text-lg hover:bg-[#F7F9FD] cursor-pointer hover:border-l-[5px] hover:border-[#2D4F93] ">
                <img src={ExportSvg} alt="raoatech" />
                <a
                  href="#"
                  onClick={handleDownloadCSV}
                  // download
                  className=" text-sm md:text-base ml-3 font-medium"
                >
                  Export
                </a>
              </li>
            </ul>
          </div>
        )}
      </nav> */}

      
    </>
  );
};

export default SideNavBar;
