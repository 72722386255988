import UserProfile from "../../components/user-profile";
import Discussion from "../../components/discussion";
import EventCalender from "../../components/events-calendar";
import AdminDashboardResourceLibrary from "../../components/admin-dashboard-resource-library";
import '../../styles/global.scss'

const RightSide = () => {
  return (
    <section className="px-5 py-12 w-full lg:w-[80%] flex flex-col lg:pr-10 space-y-6 mobile-mt-1">
      <div className=" w-full flex flex-col md:flex-row justify-between space-y-6 sm:space-y-0 sm:space-x-3">
        <div className="md:w-[70%]">
          <UserProfile />
        </div>

        <div className="md:w-[30%]">
          <Discussion />
        </div>
      </div>

      <div className=" w-full flex flex-col xl:flex-row justify-between space-y-6 sm:space-y-0 sm:space-x-3 ">
        <div className="xl:min-w-[30%] ">
          <AdminDashboardResourceLibrary />
        </div>

        <div className="xl:w-[70%]">
          <EventCalender />
        </div>
      </div>
    </section>
  );
};

export default RightSide;
