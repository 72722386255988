import SideNavBar from "../../components/side-nav-bar";
import SearchBar from "../../components/searchbar";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import { axiosInstance } from "../../utils/apiConfig";
import { useState, useEffect } from "react";
import ResourceFile from "../../components/ResourceFile";
import { searchFunction } from "../../utils/reusables";
import '../../styles/user-resource-library.scss'

const ResourcesLibrary = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [search, setSearch] = useState("");

  const renderFiles = async () => {
    try {
      const response = await axiosInstance.get("/users/get-files", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      });
      setUploadedFiles(response.data.reverse());
    } catch (error) {}
  };

  const handleSearch = (find) => {
    setSearch(find);
  };

  useEffect(() => {
    renderFiles();
  }, []);

  return (
    <>
      <div className="w-screen flex flex-col lg:flex-row lg:space-x-4 text-[#000000] mobile-mt">
        <div className="w-full lg:w-[20%]">
          <SideNavBar />
        </div>

        <div className="flex flex-col space-y-10 px-10 py-20 max-h-screen w-4/5 user-resource-library-container">
          <SearchBar onSearch={handleSearch} />

          <div className="flex flex-col space-y-3 p-2 border-solid border-2 border-gray-200 min-h-[600px] max-h-full overflow-auto recent-uploads-container">
            <h1 className="text-3xl font-medium border-b border-gray-300 py-2 pl-2 header-text">
              Recent Uploads
            </h1>

            <div className="w-full flex items-start max-w-full flex-wrap justify-between p-3 uploads-container">
              {searchFunction(uploadedFiles, search, ["filename"]).map(
                (file) => (
                  <ResourceFile key={file._id} fileinfo={file} />
                )
              )}
            </div>
          </div>
        </div>

        <div className="w-1/5 px-10 py-20 border-solid change-state-container">
          <div className="flex flex-col space-y-5 border-solid border border-[#1e1d1d] h-full py-16 px-5 ">
            <Link to={"/user/resource-library/downloads"} className="block text-lg font-medium">
              Downloads
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResourcesLibrary;
