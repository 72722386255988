import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { axiosInstance } from "../../../utils/apiConfig";
import SideNavBar from "../../../components/side-nav-bar";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../../../components/footer";
import { searchFunction } from "../../../utils/reusables";
import '../../../styles/admin-bills.scss'

function CreateNewBill() {
  const [disabled, setDisabled] = useState(true);
  const [billName, setBillName] = useState("");
  const [billAmount, setBillAmount] = useState("");
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const buttonRef = useRef();
  const studentRef = useRef();
  const graduateRef = useRef();
  const corporateRef = useRef();
  const groupsArr = [studentRef, graduateRef, corporateRef];

  const handleChange = (e) => {
    const billName = document.getElementById("billName").value;
    const billAmount = document.getElementById("billAmount").value;

    let state = false;

    for (let i = 0; i < 3; i++) {
      if (groupsArr[i].current.checked) {
        state = true;
        break;
      }
    }

    if (selectedUsers.length > 0) {
      state = true;
    }

    if (e.target.id === "billName") {
      setBillName(billName);
    } else if (e.target.id === "billAmount") {
      setBillAmount(+billAmount);
    }

    if (billName.trim().length >= 2 && billAmount > 0 && state) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleKeyDown = (e) => {
    if (
      e.keyCode !== 8 &&
      e.keyCode !== 9 &&
      e.keyCode !== 13 &&
      e.keyCode !== 46 &&
      e.keyCode !== 110 &&
      e.keyCode !== 190 &&
      (e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  };

  const createNewBillReq = async (e) => {
    e.preventDefault();

    const groups = [];
    groupsArr.forEach((group) => {
      if (group.current.checked) {
        groups.push(group.current.name);
      }
    });

    const individuals = [];
    selectedUsers.forEach((user) => {
      individuals.push(user.id);
    });

    if (groups.length > 0 && individuals.length > 0) {
      notify(
        "Bills can only be created for either groups or individuals, not both"
      );
      setDisabled(true);
      return;
    }

    buttonRef.current.innerHTML = "Please wait";
    setDisabled(true);

    try {
      const response = await axiosInstance.post(
        "/admin/create-bill",
        {
          bill_name: billName,
          bill_amount: billAmount,
          group: groups,
          individuals,
        },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("datakey")),
          },
        }
      );
      if (response.status === 201) {
        notify(response.data.message, "success");
        clearFields();
      }
    } catch (error) {
      notify("An error occured, Please try again", "error");
    }

    buttonRef.current.innerHTML = "Create Bill";
    setDisabled(false);
  };

  const clearFields = () => {
    setBillName("");
    document.getElementById("billName").value = "";

    setBillAmount("");
    document.getElementById("billAmount").value = "";

    setSearch("");
    setSelectedUsers([]);

    document.getElementById("student").checked = false;
    document.getElementById("graduate").checked = false;
    document.getElementById("corporate").checked = false;

    groupsArr.forEach((group) => {
      group.current.checked = false;
    });
  };

  const fetchUsers = () => {
    axiosInstance
      .get("/admin/getusers", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("datakey")),
        },
      })
      .then((res) => {
        const newArr = res.data.users.map((user) => {
          return {
            first_name: user.first_name,
            last_name: user.last_name,
            id: user.membership_id,
          };
        });
        setUsers(newArr);
      })
      .catch((err) => {});
  };

  const findUser = (search) => {
    if (search === "") {
      setSearchResults([]);
      return;
    }

    const searchKeys = ["first_name", "last_name", "id"];
    const results = searchFunction(users, search, searchKeys);
    setSearchResults(results);
  };

  const updateSelectedUsers = (action, user) => {
    if (action === "add") {
      let flag = false;
      selectedUsers.forEach((selectedUser) => {
        if (selectedUser.id === user.id) {
          flag = true;
        }
      });
      if (flag) {
        notify("User already added");
        return;
      }

      setSelectedUsers([...selectedUsers, user]);
    } else if (action === "remove") {
      const newArr = selectedUsers.filter((selectedUser) => {
        return selectedUser.id !== user.id;
      });
      setSelectedUsers(newArr);
    }
  };

  const notify = (text) => {
    return toast.info(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    handleChange({ target: { id: "billName" } });
  }, [selectedUsers]);

  return (
    <>
      <div className="flex flex-col lg:flex-row lg:space-x-4 text-[#000000] mobile-mt">
          <SideNavBar />

        <div className="flex flex-col space-y-10 px-10 py-20 w-screen md:w-4/5 create-new-bill-container">
          <div className="w-full border-solid border-2 border-gray-200 h-full p-6 flex flex-col create-new-bill-content-container">
            <div className="py-2 border-b-2 header-container">
              <h1 className="text-4xl font-semibold header-text">New Bill</h1>
            </div>

            <form className="mt-20 flex flex-col space-y-6  create-new-form-container">
              <div className="flex flex-col space-y-2">
                <label className="text-lg font-semibold">Name</label>
                <input
                  id="billName"
                  className="w-[70%] border-solid border-1 border-gray-200 h-12 p-2 rounded bill-name"
                  type="text"
                  placeholder="Enter bill name"
                  onChange={handleChange}
                />
                <p className="flex justify-start gap-1 text-xs mb-0 bill-info">
                  <span className="flex items-center justify-center text-[10px] text-center text-[red] italic w-[10px] h-[10px] p-[7px] shrink-0 grow-0 rounded-full border border-red-500">
                    i
                  </span>{" "}
                  <span>
                    For creation of Annual Bill Certificate, kindly use this
                    description{" "}
                    <b className="italic">ANNUAL MEMBERSHIP CERTIFICATE</b>
                  </span>
                </p>
              </div>
              <div className="flex flex-col space-y-2">
                <label className="text-lg font-semibold">Amount</label>
                <input
                  id="billAmount"
                  className="w-[170px] border-solid border-1 border-gray-200 h-12 p-2 rounded"
                  type="text"
                  placeholder="Enter bill amount"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label className="text-lg font-semibold">
                  Assign to Groups
                </label>
                <div className="flex gap-6 bill-group">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="Student"
                      id="student"
                      ref={studentRef}
                      onChange={handleChange}
                    />
                    <label htmlFor="student">Student</label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="Graduate"
                      id="graduate"
                      ref={graduateRef}
                      onChange={handleChange}
                    />
                    <label htmlFor="graduate">Graduate</label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="Corporate"
                      id="corporate"
                      ref={corporateRef}
                      onChange={handleChange}
                    />
                    <label htmlFor="corporate">Corporate</label>
                  </div>
                </div>
              </div>

              <div className="w-full border-t pt-3 flex flex-col gap-3 capitalize individual-bills-container">
                <h3 className="text-lg font-semibold py-3">For Indiviuals</h3>

                <div className="w-full pr-2 flex gap-2 individual-bills">
                  <div className="w-[50%]">
                    <div className="flex items-center">
                      <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <input
                          type={"text"}
                          className={
                            "p-4 border-none ring-2 ring-[#2D4F93] focus:outline-none focus:ring-2 focus:ring-gray-300 text-gray-900 text-sm rounded-sm w-full pl-10 placeholder-gray-400"
                          }
                          value={search}
                          placeholder="Enter Name or Membership ID"
                          onChange={(e) => {
                            setSearch(e.target.value);
                            findUser(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-[50%] h-[52px]">
                    <div className="h-full p-1 border-2 w-full flex-nowrap border-gray rounded-sm flex gap-2 overflow-x-auto scrollbar scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full">
                      {selectedUsers.map((user) => {
                        return (
                          <div
                            className="flex items-center justify-between p-3 border border-gray-200 rounded-lg min-w-[150px]"
                            key={user.id}
                          >
                            <p className="w-[80%] truncate">
                              {user.first_name} {user.last_name}
                            </p>

                            <p
                              className="text-sm font-bold cursor-pointer"
                              onClick={() =>
                                updateSelectedUsers("remove", user)
                              }
                            >
                              x
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {/* a dropdown of users that match the search */}
                <div className="w-[50%] flex flex-col gap-1 max-h-[100px] pr-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full dropdown">
                  {searchResults.map((user) => {
                    return (
                      <div
                        key={user.id}
                        className="flex items-center justify-between p-2 border border-gray-200 rounded cursor-pointer"
                        onClick={() => {
                          updateSelectedUsers("add", user);
                        }}
                      >
                        <p>
                          {user.first_name} {user.last_name}
                        </p>
                        <p>{user.membership_id}</p>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="flex flex-col space-y-2 mt-6">
                <button
                  disabled={disabled}
                  className={`md:text-base py-3 px-4 rounded-md w-fit ${
                    disabled
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-[#2D4F93] text-white"
                  } cursor-pointer`}
                  ref={buttonRef}
                  onClick={(e) => {
                    createNewBillReq(e);
                  }}
                >
                  Create Bill
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />

      <ToastContainer
        position="bottom-center"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default CreateNewBill;
