import React from "react";

const SearchBar = ({ onSearch }) => {
  const handleChange = (e) => {
    onSearch(e.target.value);
  };
  
  return (
    <>
      <div className="w-full">
        <form class="flex items-center">
          <div class="relative w-full">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type={"text"}
              class={
                "p-4 border-none ring-2 ring-[#2D4F93] focus:outline-none focus:ring-2 focus:ring-gray-300 text-gray-900 text-sm rounded-sm w-full pl-10 placeholder-gray-400"
              }
              placeholder="Search"
              onChange={handleChange}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchBar;
